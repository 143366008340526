import Col from "react-bootstrap/Col";
import React from "react";
import Row from "react-bootstrap/Row";
import PartnerCard from "./PartnerCard";
import wwf from "../../assets/img/partner/logo_wwf.svg";
import ech from "../../assets/img/partner/logo_ech_de.svg";
import min from "../../assets/img/partner/minergie_logo.svg";
// import zur from "../../assets/img/partner/logo_ugz.svg";
import hev from "../../assets/img/partner/logo_hev.png";
import fhnw from "../../assets/img/partner/logo_fhnw.svg";
import ebl from "../../assets/img/partner/logo_ebl.svg";
import ost from "../../assets/img/partner/logo_ost.svg";
import luucy from "../../assets/img/partner/logo_luucy.svg";
import hslu from "../../assets/img/partner/logo_hslu.svg";
import sct from "../../assets/img/partner/SCT_Logo_Claim_DE_RGB.svg";
import zhaw from "../../assets/img/partner/logo_zhaw.svg";
import solar from "../../assets/img/partner/logo_swissolar.png";
import sia from "../../assets/img/partner/logo_siabgt.jpg";

export default function Partners() {

    return <><Row className={'mt-5'}>
        <Col><h4>Unsere Partner</h4></Col>
    </Row>
        <Row className={'mt-3 mb-5'}>
            <PartnerCard image={wwf}/>
            <PartnerCard image={ech}/>
            <PartnerCard image={min}/>
            {/*<PartnerCard image={zur}/>*/}
            <PartnerCard image={hev}/>
            <PartnerCard image={fhnw}/>
            <PartnerCard image={ebl}/>
            <PartnerCard image={ost}/>
            <PartnerCard image={luucy}/>
            <PartnerCard image={hslu} marginTop={'10%'}/>
            <PartnerCard image={sct}/>
            <PartnerCard image={zhaw}/>
            <PartnerCard image={solar} height={'25px'} marginTop={'15%'}/>
            <PartnerCard image={sia} height={'60px'} marginTop={'8%'} />
        </Row>
    </>;
}