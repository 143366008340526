export const CurrencyDisplay = ({children, fractionDigits = 0, unit = 'CHF', showZero = false}) => {
    if (!showZero && (!children || children === 0)) return '-';
    let maxSignificant = 2;
    if(children > 1000) maxSignificant = 3;
    if(children > 10000) maxSignificant = 4;
    if(children > 100000) maxSignificant = 5;
    return children.toLocaleString(navigator.language, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
        maximumSignificantDigits: maxSignificant,
        style: 'currency',
        currency: unit
    });
};