import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import {useSelector} from "react-redux";
import {selectDataAttrs, selectDataLoading} from "../../reducers/dataSelectors";
import {useNavigate} from "react-router";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {logMessage} from "../../lib/logging";
import Accordion from "react-bootstrap/Accordion";
import {DetailFormInfoElement} from "../data-input/DetailFormInfoElement";
import {DetailFormElement} from "../data-input/DetailFormElement";
import {DetailFormDropdownElement} from "../data-input/DetailFormDropdownElement";
import {DetailFormToggleElement} from "../data-input/DetailFormToggleElement";

export const DataEntryDetails = (props) => {
    const attrs = useSelector(selectDataAttrs);
    const loading = useSelector(selectDataLoading);
    const navigate = useNavigate();

    useEffect(() => {
        if (attrs === undefined && !loading.first) {
            logMessage('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [attrs, loading, navigate]);

    if (attrs === undefined) return "";

    return <div>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>
                Weitere Details erfassen
            </PageTitle>
            <Row className={'mt-4'}>
                <Col xs={12} lg={6}>
                    <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
                        {/*<Accordion defaultActiveKey={["0"]} alwaysOpen>*/}
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Grunddaten</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <DetailFormInfoElement infoType={'address'}/>
                                    <DetailFormInfoElement infoType={'zipcity'}/>
                                    <DetailFormElement
                                        parameterName={'_building_constructionYear'}
                                        textColor={'text-secondary'}
                                    />
                                    <DetailFormDropdownElement parameterName={'_site_userType'}/>

                                    <DetailFormElement parameterName={'marginaltaxrate'}/>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Gebäude</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormDropdownElement parameterName={'_building_usageCategory2'}/>
                                <DetailFormElement parameterName={'_building_usageCategory2_area'}/>
                                <DetailFormDropdownElement parameterName={'_building_usageCategory3'}/>
                                <DetailFormElement parameterName={'_building_usageCategory3_area'}/>

                                <DetailFormElement
                                    parameterName={'_building_livingArea'}
                                    textColor={'text-secondary'}
                                />
                                <DetailFormDropdownElement parameterName={'_building_insulationStandard'}/>
                                <DetailFormDropdownElement parameterName={'_building_heatedExtensions'}/>

                                <DetailFormElement parameterName={'_building_basementHeatedFraction'}/>
                                <DetailFormElement parameterName={'_building_atticHeatedFraction'}/>

                                <DetailFormDropdownElement parameterName={'_building_facadeType'} />
                                <DetailFormDropdownElement parameterName={'_building_wallType'} />




                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Klimatisierung</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormElement parameterName={'_building_temperatureSetting'}/>
                                <DetailFormElement parameterName={'_building_infiltration'}/>

                                <DetailFormDropdownElement parameterName={'_energySystem_coolingType'}/>
                                <DetailFormElement parameterName={'_energySystem_airCondition'}/>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Solaranlage</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormToggleElement
                                    parameterName={'_solarSystem_installedArea'}
                                    textColor={'text-secondary'}
                                />
                                <DetailFormElement
                                    parameterName={'_solarSystem_installedArea'}
                                    textColor={'text-secondary'}
                                    condition={'_solarSystem_installedArea'}
                                    conditionValue={true}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Photovoltaik</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormToggleElement
                                    parameterName={'_pvSystem_installedPower'}
                                    textColor={'text-secondary'}
                                />
                                <DetailFormElement
                                    parameterName={'_pvSystem_installedPower'}
                                    textColor={'text-secondary'}
                                    condition={'_pvSystem_installedPower'}
                                    conditionValue={true}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
                <Col xs={12} lg={6}>
                    <Accordion defaultActiveKey={["0", "1", "2", "3", "4"]} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Heizung</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <DetailFormDropdownElement
                                        parameterName={'_energySystem_heating0'}
                                        textColor={'text-secondary'}
                                    />

                                    <DetailFormDropdownElement
                                        parameterName={'_energySystem_hotwater0'}
                                        textColor={'text-secondary'}
                                        condition={'_solarSystem_installedArea'}
                                        conditionValue={false}
                                    />

                                    <DetailFormDropdownElement
                                        parameterName={'_energySystem_hotwater1'}
                                        textColor={'text-secondary'}
                                        condition={'_solarSystem_installedArea'}
                                        conditionValue={true}
                                    />
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Bewohner</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormElement
                                    parameterName={'_building_occupantNumber'}
                                    textColor={'text-secondary'}
                                />
                                <DetailFormElement parameterName={'_building_daysAtHome'} />
                                <DetailFormElement parameterName={'children'} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Ausstattung</Accordion.Header>
                            <Accordion.Body>

                                <DetailFormDropdownElement parameterName={'_equipment_stove'}/>

                                <DetailFormElement
                                    parameterName={'_equipment_kitchenRenovation'}
                                    textColor={'text-secondary'}
                                />

                                <DetailFormElement
                                    parameterName={'_equipment_washingRenovation'}
                                    textColor={'text-secondary'}
                                />

                                <DetailFormDropdownElement parameterName={'_site_housingStatus'}/>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Energieverbrauch</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormDropdownElement parameterName={'_energySystem_boilerLocation'}/>
                                <DetailFormDropdownElement parameterName={'_energySystem_circulation'}/>
                                <DetailFormDropdownElement parameterName={'minergie'} />

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Renovationen</Accordion.Header>
                            <Accordion.Body>
                                <DetailFormToggleElement
                                    parameterName={'_energySystem_heating0Renovation'}
                                    textColor={'text-secondary'}
                                />

                                <DetailFormElement
                                    parameterName={'_energySystem_heating0Renovation'}
                                    textColor={'text-secondary'}
                                    condition={'_energySystem_heating0Renovation'}
                                    conditionValue={true}
                                />

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
            <Row className={'mt-3 pb-5'}>
                <Col className={'d-flex justify-content-end'}>
                    <Link className={'nav-link'} to="/dateneingabe">
                        <Button size={'sm'} style={{'width': '200px'}}>zurück</Button>
                    </Link>
                    <Link className={'nav-link'} to="/situation">
                        <Button variant={"dark"} style={{'width': '200px'}} className={'ms-3'}
                                size={'sm'}>Situationsanalyse</Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    </div>;
};