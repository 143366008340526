import {useDispatch, useSelector} from "react-redux";
import {addressSelect} from "../../reducers/addressActions";

import ListGroup from "react-bootstrap/ListGroup";
import {dataLoadFirstQuery} from "../../reducers/dataActions";
import {useNavigate} from "react-router";

export const AddressItem = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selected = useSelector((state) => state.address.selected);
    let label = props.item.attrs.label.replace('<b>', '').replace('</b>', '');
    if (selected && selected.id === props.item.id) {
        label = <strong>{label}</strong>;
    }
    return (
        <ListGroup.Item key={props.item.id}
                        onClick={onAddressItemClick}
                        className={'cursor-pointer address-item'}>
            {label}
        </ListGroup.Item>
    );

    function onAddressItemClick() {
        dispatch(addressSelect(props.item));
        dispatch(dataLoadFirstQuery());
        navigate('/dateneingabe');
    }
};