import {useDispatch, useSelector} from "react-redux";
import {selectConditionalElementFromAnywhere, selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataUpdateValue,} from "../../reducers/dataActions";
import React, {useEffect, useState} from "react";
import {DetailFormRow} from "./DetailFormRow";
import {getItemDisplayValue, getLabel, isInvalid} from "../../lib/display-utils";
import {FaEdit} from "react-icons/fa";
import {BiError} from "react-icons/bi";
import {Form, InputGroup} from "react-bootstrap";

export const DetailFormElement = ({
                                      parameterName = 'unknown',
                                      textColor = '',
                                      condition = '',
                                      conditionValue = false
                                  }) => {

    const myElement = useSelector(state => selectConditionalElementFromAnywhere(state, parameterName));
    const conditionElement = useSelector(state => selectElementFromAnywhere(state, condition));
    const dispatch = useDispatch();

    const value = getItemDisplayValue(myElement);
    const [editing, setEditing] = useState(false);
    const [editableValue, setEditableValue] = useState(value);

    useEffect(() => {
        setEditableValue(value);
    }, [value]);

    const isSupportedWidgetType = (item) => {
        switch (item.widgetType) {
            case 'guiTypeStepper':
            case 'guiTypeNoUiSlider':
            case 'guiTypeNoUiSliderCheck':
                return true;
            default:
                return false;
        }
    };

    const handleValueClick = () => {
        setEditing(true);
    };

    const handleInputChange = (e) => {
        setEditableValue(e.target.value);
    };

    const handleInputBlur = () => {
        setEditing(false);
        dispatch(dataUpdateValue({
            parameter: myElement.parameter,
            value: editableValue
        }));
    };

    const label = getLabel(myElement);

    let content = '';

    // if(!myElement) return <InvalidElement name={parameterName} useContainer={false}></InvalidElement>
    if (!myElement) return "";

    const dummyElement = {
        widgetType: myElement.widgetType,
        minValue: myElement.minValue,
        maxValue: myElement.maxValue,
        value: editableValue
    };
    const valueInvalid = isInvalid(dummyElement);

    const getFormControl = (item) => {

        if (item.unit !== '') {
            return <InputGroup size={'sm'}>
                <Form.Control type="text"
                              isInvalid={valueInvalid}
                              value={editableValue}
                              style={{textAlign: 'right'}}
                              onChange={handleInputChange}
                              onBlur={handleInputBlur}
                              autoFocus
                />
                <InputGroup.Text>{item.unit}</InputGroup.Text>
            </InputGroup>;
        }
        return <Form.Control type={'text'}
                             value={editableValue}
                             size={'sm'}
                             style={{textAlign: 'right'}}
                             onChange={handleInputChange}
                             onBlur={handleInputBlur}
                             autoFocus
        />;
    };

    content = <div>{editing ? getFormControl(myElement) : (
        <div className={'d-flex justify-content-center align-content-center cursor-pointer'} onClick={handleValueClick}>
            <InputGroup>
                <span onClick={handleValueClick}>{editableValue}{myElement.unit}</span>
                <InputGroup.Text className={'ps-3 pe-0'} style={{background: 'none', border: 'none'}}>
                    {valueInvalid ? <BiError className={'text-danger'} size={14}/> :
                        <FaEdit className={textColor} size={14}/>}
                </InputGroup.Text>
            </InputGroup>
        </div>
    )}
    </div>;

    if (!isSupportedWidgetType(myElement)) {
        content = <span className={'text-danger small'}>unsupported type {myElement.widgetType}</span>;
    }

    if (condition !== '' && conditionElement) {
        const conditionValueParsed = conditionElement.defineValue === 'true';
        if (conditionValueParsed !== conditionValue) {
            return "";
        }
    }

    return <>
        <DetailFormRow label={label}
                       showEdit={false}
                       additionalClasses={valueInvalid ? 'text-danger' : textColor}>
            {content}
        </DetailFormRow>
    </>;
};