import {createReducer} from "@reduxjs/toolkit";
import {addressSearch, addressSearchFailure, addressSearchSuccess, addressSelect} from "./addressActions";

const initialState = {
    list: [],
    loading: false,
    selected: undefined,
    error: { hasError: false, message: "", detail: undefined}
}

const addressReducer = createReducer(initialState, (builder) => {
    builder.addCase(addressSearchSuccess, (state, action) => {
        state.list = Object.assign([], action.payload)
        state.loading = false
    }).addCase(addressSelect, (state, action) => {
        state.selected = action.payload
    }).addCase(addressSearchFailure, (state, action) => {
        state.list = []
        state.loading = false
        state.error = action.payload
        state.selected = undefined
    }).addCase(addressSearch, (state, action) => {
        state.selected = undefined
        state.loading = true
    })
})

export default addressReducer