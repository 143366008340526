import React from 'react';
import {useDispatch} from "react-redux";
import {dataEnableMeasureGroup, dataLoadFifthQuery} from "../../reducers/dataActions";
import {ImStarEmpty, ImStarFull} from "react-icons/im";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const MeasureStar = ({item}) => {

    const dispatch = useDispatch();

    if (!item) return "";

    const handleStarClicked = () => {
        const newValue = item.subMeasure[0].selection === "2" ? "1" : "2";
        dispatch(settingsSetEventUID({eventUID: item.parameter}))
        dispatch(settingsSetClientEvent({clientEvent: 'editMeasure'}))
        dispatch(dataEnableMeasureGroup({parameter: item.parameter, value: newValue}));
        dispatch(dataLoadFifthQuery());
    };


    return <span className={'me-2 cursor-pointer'} style={{'top': '-3px', 'position': 'relative'}}
                 onClick={handleStarClicked}>
                    {item.subMeasure[0].selection === "2"
                        ? <ImStarFull className={'text-warning'}/>
                        : <ImStarEmpty/>}
                </span>;
};