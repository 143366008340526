import React from 'react';
import {ReactSortable} from "react-sortablejs";
import "./SortableYearComponent.css";
import {YearHeader} from "./YearHeader";
import {useDispatch, useSelector} from "react-redux";
import {selectDataActiveMeasuresOfYear} from "../../reducers/dataSelectors";
import {dataMeasureChangeYear} from "../../reducers/dataActions";
import _ from "lodash";
import {logError, logMessage} from "../../lib/logging";

export const SortableYearComponent = ({
                                          list = [],
                                          setList,
                                          bgcolor,
                                          whiteText,
                                          year = "???",
                                          includeAllYearsAfter = false,
                                          showOptionDetail = false
                                      }) => {

    const measureOfYear = useSelector(state => selectDataActiveMeasuresOfYear(state, year, includeAllYearsAfter));
    const dispatch = useDispatch();

    const updateList = (updated) => {
        const newIdList = updated.map(itm => itm.id);
        const oldIdList = measureOfYear.map(itm => itm.id);
        if (!_.isEqual(oldIdList, newIdList)) {
            if (oldIdList.length === newIdList.length) {
                // reordering inside on list - do nothing for now
                // TODO: fix / implement ordering
            } else {
                if (oldIdList.length < newIdList.length) {
                    let newKey2 = undefined;
                    newIdList.forEach(itm => {
                        if (oldIdList.indexOf(itm) === -1) {
                            newKey2 = itm;
                        }
                    });
                    if (newKey2) {
                        logMessage(`added ${newKey2} to ${year}`);
                        dispatch(dataMeasureChangeYear({
                            parameter: newKey2,
                            year
                        }));
                    } else {
                        logError('couldnt find suitable element to insert');
                    }
                } else {
                    // removed item from list - do nothing, will be removed by redux store automatically
                }
            }
        }
    };

    return (<>
        <YearHeader>{includeAllYearsAfter ? "später" : year}</YearHeader>
        <ReactSortable list={measureOfYear} setList={updateList} group={'measureYear'} className={''}
                       style={{'minHeight': '300px'}}
                       dragoverBubble={true}
                       ghostClass={'sortable-ghost'}
                       chosenClass={'sortable-chosen'}
                       dragClass={'sortable-drag'}>
            {measureOfYear.map((item) => (
                <div style={{'height': '50px', 'backgroundColor': bgcolor}}
                     className={'mb-2'}
                     key={item.id}>
                    <div className={`h-100 d-flex align-items-center justify-content-center ${whiteText ? "text-white" : ""}`}>
                        <div>
                            {item.name}
                            { showOptionDetail ? <><br /><div className={'small'}>({item.option})</div></> : ''}
                        </div>

                    </div>

                </div>))}
        </ReactSortable></>);
};