import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDataError,
    selectDataLoaded,
    selectDataLoading,
    selectDataMeasures,
    selectDataSelectedAddress
} from "../../reducers/dataSelectors";
import {useNavigate} from "react-router";
import {logError, logMessage} from "../../lib/logging";
import {MeasureGroup} from "../measures/MeasureGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ActiveMeasureList} from "../measures/ActiveMeasureList";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import imgHouse from "../../assets/measures/house.jpg";
import "./Measures.css";
import {DefineTuneTargets} from "../measures/DefineTuneTargets";
import {RxCrossCircled} from "react-icons/rx";
import {ImStarFull} from "react-icons/im";
import {LiaToggleOffSolid, LiaToggleOnSolid} from "react-icons/lia";
import {LoadingMeasures} from "../layout/LoadingMeasures";
import {settingsSetMeasureExpertView} from "../../reducers/settingsActions";

export const Measures = (props) => {
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const measures = useSelector(selectDataMeasures);
    const loading = useSelector(selectDataLoading);
    const loaded = useSelector(selectDataLoaded);
    const error = useSelector(selectDataError);
    const [clientEvent, eventUID] = useSelector(state => [state.settings.clientEvent, state.settings.eventUID]);
    const showClientEventDebug = useSelector(state => state.settings.showClientEventDebug);
    const measureLoadingState = useSelector(state => state.settings.measureLoadingState);
    const expertEnabled = useSelector(state => state.settings.measureExpertView);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedAddress === undefined) {
            logMessage('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [selectedAddress, loading, navigate]);

    function getLoadingIndicator() {
        return <div>
            <LoadingMeasures/>
            {/*<LoadingMessage>Massnahmen werden geladen ...</LoadingMessage>*/}
        </div>;
    }

    function getError() {
        logError(error);
        return <div>
            <h3>Error</h3>
            <p>{error.message}</p>
        </div>;
    }

    const getClientEventDebug = () => {
        if (!showClientEventDebug) return "";
        return <div className={'mt-5 bg-warning bg-opacity-10 p-3'}>
            <Container>
                <Row>
                    <Col xs={3}>ClientEvent</Col>
                    <Col>{clientEvent}</Col>
                </Row>
                <Row>
                    <Col xs={3}>EventUID</Col>
                    <Col>{eventUID ? eventUID : 'undefined'}</Col>
                </Row>
            </Container>
        </div>;
    };

    const getMeasureContent = () => {
        return <>
            <Row>
                <Col xs={12} lg={7} xl={6} xxl={5}>
                    <div className={'image-container'}>
                        <img src={imgHouse} alt={''}/>
                        {getMeasureGroups()}
                    </div>
                    <Row><Col className={'small'}>Legende für Massnahmen:</Col></Row>
                    <Row className={'small mb-3'}>
                        <Col xs={"auto"} className={''}>
                            <RxCrossCircled size={15} className={'text-danger me-1'}/> weglassen
                        </Col>
                        <Col xs={"auto"} className={'text-secondary'}>
                            <LiaToggleOffSolid size={20} className={'me-1'}/> nicht genutzt
                        </Col>
                        <Col xs={"auto"} className={'text-success'}><LiaToggleOnSolid size={20} className={'me-1'}/>empfohlen</Col>
                        <Col xs={"auto"} className={''}>
                            <ImStarFull className={'text-warning me-1'}/>sicher umsetzen
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <ActiveMeasureList/>
                    {getClientEventDebug()}
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} xl={5} style={{'height': '300px'}}>
                    <DefineTuneTargets/>
                </Col>
                <Col xs={12} lg={{span: 2, offset: 4}} xl={{span: 2, offset: 5}}
                     className={'d-flex justify-content-end'} style={{'height': '30px'}}>
                    <Link className={'nav-link'} to="/massnahmenplanung">
                        <Button size={'sm'} style={{'width': '200px'}}>Massnahmenplanung</Button>
                    </Link>
                </Col>
            </Row>
        </>;
    };

    const getMeasureGroups = () => {
        if (!measures) return <div className={'text-danger'}>No Measures available</div>;
        return measures.map(itm => {
            return <MeasureGroup item={itm} key={itm.parameter}/>;
        });
    };

    const getContent = () => {
        let content = '';
        if (measureLoadingState <= 100 || loading.fourth) {
            content = getLoadingIndicator();
        } else {
            if (!loading.fourth && loaded.fourth === false) content = getError();
            if (!loading.fourth && loaded.fourth) content = getMeasureContent();
        }
        return content;
    };

    const handleExpertModeClicked = (e) => {
        console.log(e.target.checked)
        dispatch(settingsSetMeasureExpertView({enabled: e.target.checked}))
    }

    return <>
        <NavBreadcrumb/>
        <Container>
            <Row>
                <Col>
                    <PageTitle showLoadingSuffix={loading.fifth}>Massnahmen</PageTitle>
                </Col>
                <Col xs={"auto"} className={"small pe-0"}>
                    <Form.Check size={1}
                        reverse
                        label="Experten-Modus"
                        name="group1"
                                checked={expertEnabled}
                                onChange={handleExpertModeClicked}
                        type="checkbox"
                        id={`expertcheck`}
                    />
                </Col>
            </Row>
            {getContent()}
        </Container>
    </>;
};