import React from 'react';
import Row from "react-bootstrap/Row";
import {InputContainer} from "../layout/InputContainer";

export const InvalidElement = ({name = 'unknown', useContainer = true}) => {

    const mainContent = <Row>
                <span className={'text-danger mb-2 small'}>
                    invalid element: <strong>{name}</strong>
                </span>
    </Row>;

    if (useContainer) return <InputContainer>{mainContent}</InputContainer>;
    return mainContent;
};
