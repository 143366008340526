import {useSelector} from "react-redux";
import {AddressItem} from "./AddressItem";

import React from 'react';
import ListGroup from "react-bootstrap/ListGroup";

export const AddressList = () => {
    const addresses = useSelector((state) => state.address.list);
    const items = addresses.map(adr => <AddressItem item={adr} key={adr.id}/>);

    if (addresses.length === 0) {
        return <></>;
    } else {
        return <ListGroup className={'mb-5'}>{items}</ListGroup>;
    }
};