import {createReducer} from "@reduxjs/toolkit";
import {
    settingsChangeDebugUrl,
    settingsSetClientEvent,
    settingsSetEventUID, settingsSetMeasureExpertView, settingsSetMeasureLoading,
    settingsToggleClientEventDebug,
    settingsToggleDebug,
    settingsUseTuneTargets
} from "./settingsActions";

const initialState = {
    localDebug: false,
    localDebugBaseUrl: 'http://localhost:8080/tachionserver',
    useTuneTargets: false,
    showClientEventDebug: false,
    clientEvent: 'optimize',
    eventUID: undefined,
    measureLoadingState: -1,
    measureExpertView: false,
};

const settingsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(settingsToggleDebug, (state, action) => {
            state.localDebug = action.payload.value;
        })
        .addCase(settingsChangeDebugUrl, (state, action) => {
            state.localDebugBaseUrl = action.payload.url;
        })
        .addCase(settingsUseTuneTargets, (state, action) => {
            state.useTuneTargets = action.payload.tuneTargets;
        })
        .addCase(settingsSetClientEvent, (state, action) => {
            state.clientEvent = action.payload.clientEvent;
        })
        .addCase(settingsSetEventUID, (state, action) => {
            state.eventUID = action.payload.eventUID;
        })
        .addCase(settingsToggleClientEventDebug, (state, action) => {
            state.showClientEventDebug = action.payload.clientEventDebug;
        })
        .addCase(settingsSetMeasureLoading, (state, action) => {
            state.measureLoadingState = action.payload.value;
        })
        .addCase(settingsSetMeasureExpertView, (state, action) => {
        state.measureExpertView = action.payload.enabled;
    });
});

export default settingsReducer;