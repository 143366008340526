import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {MEASURES_SETTINGS} from "../../config";
import {SortableYearComponent} from "./SortableYearComponent";

export const SortableYearListComponent = ({maxElements }) => {

    const showOptionDetail = false; // TODO: move to central CONFIG

    const currentYear = new Date().getFullYear()+1;
    const yearColumns = [];
    for (let i = 0; i < maxElements; i++) {
        let classes = 'h-100 ';
        let includeAllYearAfter = false;
        const currentYearString = (currentYear + i).toString();
        const bgcolor = MEASURES_SETTINGS['categoryColors'][i];
        const whiteText = MEASURES_SETTINGS['categoryWhiteTextColor'][i];
        if (i === maxElements - 1) {
            classes = `${classes} bg-dark bg-opacity-10 ms-3`;
            includeAllYearAfter = true;
        } else {
            if (i % 2 === 0) classes = `${classes} bg-light`;
        }
        yearColumns.push(
            <Col className={classes} key={`yearcol-${currentYearString}`}>
                <SortableYearComponent year={currentYearString}
                                       bgcolor={bgcolor}
                                       whiteText={whiteText}
                                       showOptionDetail={showOptionDetail}
                                       includeAllYearsAfter={includeAllYearAfter}
                />
            </Col>
        );
    }
    return <Row style={{'minHeight': '300px'}} className={'mt-3'}>
        {yearColumns}
    </Row>;
};