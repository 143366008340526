import React, {useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import {ProgressBar} from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import ProgressBar from "@ramonak/react-progress-bar"

export const Demo = (props) => {

    const [current, setCurrent] = useState(0);
    const [isRunning, setRunning] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const currentRef = useRef(current);
    currentRef.current = current;
    const isRunningRef = useRef(isRunning);
    isRunningRef.current = isRunning;

    const useRandomTick = true;

    const texts = [
        "Wohnfläche berechnen",
        "Gebäudehülle analysieren",
        "Gebäude-Luft-Infiltration analysieren",
        "Ausstattung analysieren",
        "Ausbaustandard berechnen",
        "Heizungstyp auswählen",
        "Heizungstyp optimieren",
        "Heizungstyp optimieren",
        "Solaranlage analysieren",
        "Solaranlage optimieren",
        "Photovoltaik analysieren",
        "Photovoltaik optimieren",
        "Dach analysieren",
        "Beleuchtung analysieren",
        "Lüftung analysieren",
        <>CO<sub>2</sub> reduzieren</>,
        "Wohnkomfort erhöhen",
        "Langfristige Werterhaltung",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
    ];

    const incrementCurrent = () => {
        let myCurrent = currentRef.current;
        if (myCurrent < 100) {
            let newVal = myCurrent + 1 + getRandomNumber(2);
            if (newVal > 100) {
                newVal = 100;
            }
            if (isRunningRef.current) {
                if (newVal === 100) {
                    setRunning(false);
                    setEndTime(Date.now());
                } else {
                    createTimeout();
                }

            }
            setCurrent(newVal);

        }
    };

    const resetCurrent = () => {
        setCurrent(0);
        setRunning(false);
        setEndTime(0);
        setStartTime(0);
    };

    const getRandomNumber = (max) => {
        return Math.floor(Math.random() * max) + 1;
    };

    const createTimeout = () => {
        let timeoutDuration = 100;
        if (useRandomTick) {
            timeoutDuration = (getRandomNumber(20)) * 50;
        }
        setTimeout(incrementCurrent, timeoutDuration);
    };

    const startTimer = () => {
        if (!isRunning) {
            setRunning(true);
            setCurrent(0);
            setStartTime(Date.now());
            setEndTime(0);
            createTimeout();
        }
    };

    const getRunTimeInfo = () => {
        return <>
            {startTime !== 0 && endTime === 0 ?
                <p className={'small'}>Duration: {(Date.now() - startTime) / 1000} s</p> : ''}
            {endTime !== 0 ? <p className={'small'}>Duration: {(endTime - startTime) / 1000} s</p> : ''}
        </>;
    };

    const getCurrentText = () => {
        let currentText = 'Massnahmen werden geladen ...';
        let customText = ''
        if (!isRunning && current === 100) {
            currentText = 'Massnahmen wurden geladen.';
        } else {
            if (current === 0) {
                currentText = '';
            } else {
                let myOpt = Math.floor((current / 100) * texts.length);
                customText = texts[myOpt]
            }
        }

        return <p className={'mt-3'}>{currentText} {customText}</p>;
    };

    return <>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>Demo</PageTitle>

            <div className={'mt-3'}>
                <Button size={"sm"} onClick={incrementCurrent}>Incr</Button>
                <Button className={'ms-3'} variant={"danger"} size={"sm"} onClick={resetCurrent}>Reset</Button>
                <Button disabled={isRunning} className={'ms-3'} variant={"success"} size={"sm"}
                        onClick={startTimer}>Start</Button>
            </div>

            {getCurrentText()}

            <ProgressBar now={current}
                         animated={isRunning || (current !== 0 && current !== 100)} label={`${current} %`}
                         variant={current === 100 ? "success" : 'primary'}
            />


            {getRunTimeInfo()}
        </Container>
    </>;
};