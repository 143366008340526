import {call, put, takeLatest, select, putResolve} from 'redux-saga/effects';
import _ from 'lodash';

import {
    selectDataAttrs,
    selectDataDefineTargets,
    selectDataEnergyAnalysis,
    selectDataFirstQuery, selectDataMeasures, selectDataScenario,
    selectDataSecondQuery, selectDataSelectedAddress, selectDataTuneTargets,
} from "../reducers/dataSelectors";

import {
    dataLoadFifthQuery,
    dataLoadFifthQueryFailure,
    dataLoadFifthQuerySuccess,
    dataLoadFirstQuery,
    dataLoadFirstQueryFailure,
    dataLoadFirstQuerySuccess,
    dataLoadFourthQuery,
    dataLoadFourthQueryFailure,
    dataLoadFourthQuerySuccess,
    dataLoadSecondQuery,
    dataLoadSecondQueryFailure,
    dataLoadSecondQuerySuccess,
    dataLoadThirdQuery,
    dataLoadThirdQueryFailure,
    dataLoadThirdQuerySuccess,
} from "../reducers/dataActions";
import {postUrl} from "../lib/web-request";
import {API_MODES, TACHION_BASE_URL, TACHION_URL_SUFFIX} from "../config";
import {getDataErrorObject} from "../reducers/dataReducer";
import {logMessage} from "../lib/logging";

const workers = [
    takeLatest(dataLoadFirstQuery, workerLoadFirstQuery),
    takeLatest(dataLoadSecondQuery, workerLoadSecondQuery),
    takeLatest(dataLoadThirdQuery, workerLoadThirdQuery),
    takeLatest(dataLoadFourthQuery, workerLoadFourthQuery),
    takeLatest(dataLoadFifthQuery, workerLoadFifthQuery),
];

function getAttrsPayloadForFirstQuery(attrs, egid = null) {
    // Use egid if provided, otherwise use attrs.featureId

    if (egid) {
        // Return mock payload when egid is provided
        return {
            "featureId": egid,
            "canton": "XX",
            "detail": "",
            "plz4": "9999",
            "strname1": "",
            "gdename": "",
            "num": "0",
            "strname1": "",
            "x": "200000",
            "y": "600000"
        };
    } else {
        // Existing logic for when egid is not provided
        // Extract values from attrs and return appropriate payload
        const featureId = attrs.featureId;
        const lbl = attrs.label;
        const detail = attrs.detail;

        const canton = detail.substring(detail.length - 2).toUpperCase();
        const fullAdrNew = lbl.replace(' <b>', ', ').replace('</b>', '');
        const plzcity = fullAdrNew.substring(fullAdrNew.indexOf(', ') + 2);
        const myplz = plzcity.substring(0, 4);
        const mycity = plzcity.substring(4);
        const myAdr1 = fullAdrNew.substring(0, fullAdrNew.indexOf(', '));

        return {
            "featureId": featureId,
            "gdename": mycity,
            "strname1": myAdr1,
            "x": attrs.x,
            "y": attrs.y,
            "plz4": myplz,
            "detail": fullAdrNew,
            "canton": canton,
            "num": attrs.num
        };
    }
    
}

function* allRequiredKeysAvailableInResult(result, queryType, requiredKeys, errorAction) {
    const availableKeys = Object.keys(result);
    for (const key of requiredKeys) {
        if (availableKeys.indexOf(key) === -1) {
            const myError = getDataErrorObject(true, `${queryType}: attribute ${key} not in response`);
            logMessage(myError.message);
            yield put(errorAction(myError));
            return false;
        }
    }
    return true;
}

function* getUrl() {
    const settings = yield select(state => state.settings);
    let myUrl = '';
    if (settings.localDebug) {
        myUrl = `${settings.localDebugBaseUrl}${TACHION_URL_SUFFIX}`;
    } else {
        myUrl = `${TACHION_BASE_URL}${TACHION_URL_SUFFIX}`;
    }
    return myUrl;
}

function* workerLoadFirstQuery(action) {
    try {
        const egid = action.payload; // Extract egid from the action payload
        let attrs;
        let selectedAddress

        if (egid) {
            // If egid is provided, use it
            selectedAddress = yield select(selectDataSelectedAddress);
            attrs = getAttrsPayloadForFirstQuery(null, egid);
        } else {
            // If egid is not provided, use the selectedAddress from the state
            selectedAddress = yield select(selectDataSelectedAddress);
            attrs = getAttrsPayloadForFirstQuery(selectedAddress.attrs);
        }
        const payload = {
            "apiKey": process.env.REACT_APP_API_KEY,
            "apiMode": API_MODES.first,
            "attrs": attrs
        };

        const TACHION_URL = yield getUrl();

        const response = yield call(postUrl, TACHION_URL, payload);
        const result = response.data;
        if (!result.valid) {
            const myError = getDataErrorObject(true, "q1: invalid response", result.errorMessageForUser, result.errorCode);
            logMessage(myError.message);
            logMessage(result);
            yield put(dataLoadFirstQueryFailure(myError));
        } else {

            const expectedKeys = ['firstQuery', 'attrs', 'scene'];
            if (allRequiredKeysAvailableInResult(result, 'q1', expectedKeys, dataLoadFirstQueryFailure)) {
                const parsedResponse = _.pick(result, expectedKeys);
                logMessage("first query: success");
                yield putResolve(dataLoadFirstQuerySuccess(parsedResponse));
                yield put(dataLoadSecondQuery());
            }
        }

    } catch (error) {
        const myError = getDataErrorObject(true, 'q1: error executing query', error);
        logMessage(myError.message, myError.detail);
        yield put(dataLoadFirstQueryFailure(myError));
    }
}

function* workerLoadSecondQuery() {
    try {
        const firstQuery = yield select(selectDataFirstQuery);
        const attrs = yield select(selectDataAttrs);

        const payload = {
            "apiKey": process.env.REACT_APP_API_KEY,
            "apiMode": API_MODES.second,
            "attrs": attrs,
            "firstQuery": firstQuery
        };

        const TACHION_URL = yield getUrl();
        const response = yield call(postUrl, TACHION_URL, payload);
        const result = response.data;
        if (!result.valid) {
            const myError = getDataErrorObject(true, "q2: invalid response");
            logMessage(myError.message);
            logMessage(result);
            yield put(dataLoadSecondQueryFailure(myError));
        } else {
            const expectedKeys = ['firstQuery', 'attrs', 'defineTargets', 'energyAnalysis'];
            if (allRequiredKeysAvailableInResult(result, 'q2', expectedKeys, dataLoadSecondQueryFailure)) {
                const parsedResponse = _.pick(result, expectedKeys);
                logMessage("second query: success");
                yield putResolve(dataLoadSecondQuerySuccess(parsedResponse));
                yield put(dataLoadThirdQuery());
            }
        }

    } catch (error) {
        const myError = getDataErrorObject(true, "q2: error executing query", error);
        logMessage(myError.message, myError.detail);
        yield put(dataLoadSecondQueryFailure(myError));
    }
}

function* workerLoadThirdQuery() {
    try {
        const firstQuery = yield select(selectDataFirstQuery);
        const attrs = yield select(selectDataAttrs);
        const defineTargets = yield select(selectDataDefineTargets);
        const energyAnalysis = yield select(selectDataEnergyAnalysis);

        const payload = {
            "apiKey": process.env.REACT_APP_API_KEY,
            "apiMode": API_MODES.third,
            "attrs": attrs,
            "firstQuery": firstQuery,
            "defineTargets": defineTargets,
            "energyAnalysis": energyAnalysis
        };

        const TACHION_URL = yield getUrl();
        const response = yield call(postUrl, TACHION_URL, payload);
        const result = response.data;
        if (!result.valid) {
            const myError = getDataErrorObject(true, "q3: invalid response");
            logMessage(myError.message);
            logMessage(result);
            yield put(dataLoadThirdQueryFailure(myError));
        } else {
            const expectedKeys = ['firstQuery', 'attrs', 'defineTargets', 'secondQuery'];
            if (allRequiredKeysAvailableInResult(result, 'q3', expectedKeys, dataLoadThirdQueryFailure)) {
                const parsedResponse = _.pick(result, expectedKeys);
                logMessage("third query: success");
                yield put(dataLoadThirdQuerySuccess(parsedResponse));
            }
        }

    } catch (error) {
        const myError = getDataErrorObject(true, "q3: error executing query", error);
        logMessage(myError.message, myError.detail);
        yield put(dataLoadThirdQueryFailure(myError));
    }
}

function* workerLoadFourthQuery() {
    try {
        const firstQuery = yield select(selectDataFirstQuery);
        const attrs = yield select(selectDataAttrs);
        const defineTargets = yield select(selectDataDefineTargets);
        const secondQuery = yield select(selectDataSecondQuery);

        const payload = {
            "apiKey": process.env.REACT_APP_API_KEY,
            "apiMode": API_MODES.fourth,
            "attrs": attrs,
            "firstQuery": firstQuery,
            "defineTargets": defineTargets,
            "secondQuery": secondQuery
        };

        const startTime = Date.now();
        const TACHION_URL = yield getUrl();
        const response = yield call(postUrl, TACHION_URL, payload);
        const endTime = Date.now();
        console.log(`4th Query finished in ${(endTime - startTime)/1000}s`)
        const result = response.data;
        if (!result.valid) {
            const myError = getDataErrorObject(true, "q4: invalid response");
            logMessage(myError.message);
            logMessage(result);
            yield put(dataLoadFourthQueryFailure(myError));
        } else {
            const expectedKeys = ["attrs", "energyAnalysis", "tuneTargets", "fourthQuery", "scenario", "measures"];
            if (allRequiredKeysAvailableInResult(result, 'q4', expectedKeys, dataLoadFourthQueryFailure)) {
                const parsedResponse = _.pick(result, expectedKeys);
                logMessage("fourth query: success");
                yield put(dataLoadFourthQuerySuccess(parsedResponse));
                yield put(dataLoadFifthQuery());
            }
        }

    } catch (error) {
        const myError = getDataErrorObject(true, "q4: error executing query", error);
        logMessage(myError.message, myError.detail);
        yield put(dataLoadFourthQueryFailure(myError));
    }
}

function* workerLoadFifthQuery() {
    try {
        const firstQuery = yield select(selectDataFirstQuery);
        const attrs = yield select(selectDataAttrs);
        // const defineTargets = yield select(selectDataDefineTargets);
        const secondQuery = yield select(selectDataSecondQuery);
        const fourthQuery = [...firstQuery, ...secondQuery];
        const measures = yield select(selectDataMeasures);
        const scenario = yield select(selectDataScenario);
        const tuneTargets = yield select(selectDataTuneTargets);
        const energyAnalysis = yield select(selectDataEnergyAnalysis);
        const clientEvent = yield select(state => state.settings.clientEvent);
        const eventUID = yield select(state => state.settings.eventUID);

        const payload = {
            "apiKey": process.env.REACT_APP_API_KEY,
            "apiMode": API_MODES.fifth,
            "attrs": attrs,
            "energyAnalysis": energyAnalysis,
            "tuneTargets": tuneTargets,
            "fourthQuery": fourthQuery,
            "scenario": scenario,
            "measures": measures,
            "clientEvent": clientEvent,
            "eventUID": eventUID
        };

        const TACHION_URL = yield getUrl();
        const response = yield call(postUrl, TACHION_URL, payload);
        const result = response.data;
        if (!result.valid) {
            const myError = getDataErrorObject(true, "q5: invalid response");
            logMessage(myError.message);
            logMessage(result);
            yield put(dataLoadFifthQueryFailure(myError));
        } else {
            const expectedKeys = ["attrs", "energyAnalysis", "tuneTargets", "fourthQuery", "measures"];
            if (allRequiredKeysAvailableInResult(result, 'q5', expectedKeys, dataLoadFourthQueryFailure)) {
                const parsedResponse = _.pick(result, expectedKeys);
                logMessage("fifth query: success");
                yield put(dataLoadFifthQuerySuccess(parsedResponse));
            }
        }
    } catch (error) {
        console.log('fifth error', error);
        const myError = getDataErrorObject(true, "q4: error executing query", error);
        logMessage(myError.message, myError.detail);
        yield put(dataLoadFifthQueryFailure(myError));
    }
}


export default workers;