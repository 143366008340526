import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataUpdateValue} from "../../reducers/dataActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {InputGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {InputContainer} from "../layout/InputContainer";
import {logError} from "../../lib/logging";
import {InvalidElement} from "./InvalidElement";
import {getItemDisplayValue, getLabel, isInvalid} from "../../lib/display-utils";

export const MainFormElement = ({
                                    parameterName = 'unknown',
                                    multiRow = true,
                                    yearParameter = '',
                                    condition = '',
                                    conditionValue = false,
                                    labelOverride = ''
                                }) => {

    const myElement = useSelector(state => selectElementFromAnywhere(state, parameterName));
    const yearElement = useSelector(state => selectElementFromAnywhere(state, yearParameter));
    const conditionElement = useSelector(state => selectElementFromAnywhere(state, condition));

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const newValue = event.target.value;
        let parsedNewValue = newValue;

        switch (myElement.widgetType) {
            case 'guiTypeDropdown': {
                // no parsing needed for dropdown
                break;
            }
            case 'guiTypeStepper':
            case 'guiTypeNoUiSlider': {
                parsedNewValue = isNaN(newValue) ? newValue : Number(newValue);
                break;
            }
            default: {
                logError(`widgetType not implemented in handleChange: ${myElement.widgetType}`);
                return; // return to bypass dispatch
            }

        }
        dispatch(dataUpdateValue({
            parameter: myElement.parameter,
            value: parsedNewValue
        }));
    };

    const handleYearChange = (event) => {
        const newValue = event.target.value;
        switch (yearElement.widgetType) {
            case 'guiTypeStepper':
            case 'guiTypeNoUiSlider':
            case 'guiTypeNoUiSliderCheck': {
                const parsedNewValue = isNaN(newValue) ? newValue : Number(newValue);
                dispatch(dataUpdateValue({
                    parameter: yearElement.parameter, value: parsedNewValue
                }));
                break;
            }
            default: {
                logError(`widgetType not implemented in handleChange: ${yearElement.widgetType}`);
            }
        }
    };

    const getFormControl = (item) => {
        let value = getItemDisplayValue(item);
        const label = getLabel(item);

        if (item.widgetType === 'guiTypeDropdown') {
            return <Form.Select size={'sm'} aria-label={label} onChange={handleChange} value={item.value}>
                {item.options.map(option => {
                    return <option key={option.uid} value={option.uid}>{option.displayText}</option>;
                })}
            </Form.Select>;
        }
        if (item.unit !== '') {
            return <InputGroup size={'sm'}>
                <Form.Control type="text"
                              isInvalid={isInvalid(item)}
                              value={value}
                              onChange={handleChange}
                />
                <InputGroup.Text>{item.unit}</InputGroup.Text>
            </InputGroup>;
        } else {
            return <Form.Control type="text"
                                 size={'sm'}
                                 isInvalid={isInvalid(item)}
                                 onChange={handleChange}
                                 value={value}/>;
        }
    };

    const getYearControl = () => {
        if (yearParameter === "") return "";
        if (yearElement === undefined) return <div className={'text-danger'}>Error: YearElement undefined</div>;

        return <>
            <Form.Label column={'sm'}>seit</Form.Label>
            <Col sm={{span: 4}}>
                <Form.Control size={'sm'} isInvalid={isInvalid(yearElement)} type="text"
                              onChange={handleYearChange} value={yearElement.value}/>
            </Col>
        </>;
    };

    const renderMultiRowField = (item) => {
        let label = getLabel(item);
        if (labelOverride) label = labelOverride;
        return <InputContainer>
            <Row className={'align-items-center'}>
                <Col>
                    <Form.Group className="mb-2" controlId={`form1.${item.parameter}`}>
                        <Form.Label column={'sm'}>{label}</Form.Label>
                        {getFormControl(item)}
                    </Form.Group>
                </Col>
            </Row>
        </InputContainer>;
    };

    const renderSingleRowField = (item) => {
        let label = getLabel(item);
        if (labelOverride) label = labelOverride;
        return <InputContainer>
            <Row>
                <Col>
                    <Form.Group className={'mb-2'} as={Row} controlId={`form1.${item.parameter}`}>
                        <Form.Label column={'sm'}>{label}</Form.Label>
                        <Col sm={{span: yearElement ? 5 : 9}}>
                            {getFormControl(item)}
                        </Col>
                        {getYearControl()}
                    </Form.Group>
                </Col>
            </Row>
        </InputContainer>;
    };

    const renderField = (item) => {
        if (multiRow) {
            return renderMultiRowField(item);
        } else {
            return renderSingleRowField(item);
        }
    };

    if (myElement === undefined) return <InvalidElement name={parameterName}/>;

    if (condition !== '' && conditionElement) {
        const conditionValueParsed = conditionElement.defineValue === 'true';
        if (conditionValueParsed !== conditionValue) {
            return "";
        }
    }

    return (<div id={myElement.parameter}>
        {renderField(myElement)}
    </div>);
};
