import {call, put, takeLatest} from 'redux-saga/effects'
import {addressSearch, addressSearchFailure, addressSearchSuccess} from "../reducers/addressActions";
import {fetchUrl} from "../lib/web-request";
import {logMessage} from "../lib/logging";

const SEARCH_BASE_URL = 'https://api3.geo.admin.ch/rest/services/api/SearchServer?lang=de&type=locations&limit=10&sr=21781&&origins=address&searchText='

const workers = [
  takeLatest(addressSearch, workerFetchAddresses)
]

function* workerFetchAddresses(action) {
  try {
    const pageUrl = SEARCH_BASE_URL + action.payload

    const response = yield call(fetchUrl, pageUrl)
    const result = response.data.results

    yield put(addressSearchSuccess(result))
  } catch (error) {
    const myError = {
      hasError: true,
      message: 'loading addresses failed',
      detail: error['errorMessageForUser'],
      code: error['errorCode']
    }
    logMessage(myError.message, myError.detail)
    yield put(addressSearchFailure(myError))
  }
}
export default workers