export const NumberDisplay = ({children, fractionDigits = 0, prefix = '', suffix = ''}) => {
    if (!children || children === 0) return '-';
    let fullSuffix = '';
    let fullPrefix = '';
    if (suffix !== '') fullSuffix = ` ${suffix}`;
    if (prefix !== '') fullPrefix = `${prefix} `;
    return `${fullPrefix}${children.toLocaleString(navigator.language,
        {
            maximumFractionDigits: fractionDigits,
        })}${fullSuffix}`;
};