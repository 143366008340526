import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import imgOpenDataPicture from "../../assets/img/home/icon_open-data.svg";
import imgSiaNorms from "../../assets/img/home/icon_sia-norms_v1.svg";
import imgWeatherData from "../../assets/img/home/icon_weather-data_1.svg";

export default function HowTo() {

    return <>
        <Row className={'mt-5'}>
            <Col><h3>So funktioniert es:</h3></Col>
        </Row>
        <Row className={'mt-3'}>
            <Col className={'d-flex align-items-stretch mb-3 mb-lg-0'} xs={12} md={6} lg={4}>
                <Card>
                    <Card.Img variant="top" src={imgOpenDataPicture}/>
                    <Card.Body>
                        <Card.Title>Open Data</Card.Title>
                        <Card.Text>
                            Bund, Kantone und Gemeinden stellen immer mehr Daten online zu Verfügung.
                            Mithilfe
                            dieser anonymen Daten lassen sich zahlreiche, nützliche Dienstleistungen
                            erbringen.
                            myEnergyGuide ist eine davon.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col className={'d-flex align-items-stretch mb-3 mb-lg-0'} xs={12} md={6} lg={4}>
                <Card>
                    <Card.Img variant="top" src={imgSiaNorms}/>
                    <Card.Body>
                        <Card.Title>SIA Normen</Card.Title>
                        <Card.Text>
                            Für Gebäude und Hausinstallationen gibt es zahlreiche Normen und Vorschriften.
                            myEnergyGuide geht davon aus, dass dein Haus nach diesen Regeln geplant und gebaut
                            wurde. Dadurch erübrigen sich viele technische Fragen.
                        </Card.Text>
                    </Card.Body>
                </Card>

            </Col>
            <Col className={'d-flex align-items-stretch mb-3 mb-lg-0'} xs={12} md={6} lg={4}>
                <Card>
                    <Card.Img variant="top" src={imgWeatherData}/>
                    <Card.Body>
                        <Card.Title>Wetterdaten</Card.Title>
                        <Card.Text>
                            Die Zahl der Sonnenstunden, die Lufttemperatur und weitere Umweltfaktoren
                            beeinflussen den Energieverbrauch deines Hauses. myEnergyGuide hat Wetterdaten für
                            die ganze Schweiz und schlägt Lösungen vor, die auf dein lokales Klima abgestimmt
                            sind.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>;
}