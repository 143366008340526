import React from 'react';
import {useSelector} from "react-redux";
import {selectDataActiveMeasuresOfYear} from "../../reducers/dataSelectors";
import {CurrencyDisplay} from "./display/CurrencyDisplay";
import {NumberDisplay} from "./display/NumberDisplay";

export const MeasureSavingsRowElement = ({year, attribute}) => {

    const yearMeasures = useSelector(state => selectDataActiveMeasuresOfYear(state, year.toString(), false, false));
    const value = yearMeasures.reduce((total, item) => total + Number(item.subMeasure[0].subOption[0].cost[attribute]), Number(0));
    if (attribute === 'CO2Savings') {
        return <NumberDisplay suffix={'kg'}>{value}</NumberDisplay>;
    } else {
        return <CurrencyDisplay>{value}</CurrencyDisplay>;
    }
};