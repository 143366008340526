import {useSelector} from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    selectDataAttrs,
    selectDataDefineTargets,
    selectDataEnergyAnalysis,
    selectDataFirstQuery,
    selectDataMeasures,
    selectDataScene,
    selectDataSecondQuery,
    selectDataTuneTargets
} from "../../reducers/dataSelectors";

export const HouseDataDebug = () => {
    const houseAttrs = useSelector(selectDataAttrs);
    const firstQuery = useSelector(selectDataFirstQuery);
    const scene = useSelector(selectDataScene);
    const secondQuery = useSelector(selectDataSecondQuery);
    const energyAnalysis = useSelector(selectDataEnergyAnalysis);
    const defineTargets = useSelector(selectDataDefineTargets);
    const tuneTargets = useSelector(selectDataTuneTargets);
    const measures = useSelector(selectDataMeasures);

    function printKeyValues(obj, useLargeLabelCol = false) {
        const content = [];

        if (obj) {
            Object.keys(obj).forEach((key, index) => {
                const element = obj[key];
                const countEntriesKeys = ['parcel', 'horizon', 'terrain', 'buildings'];

                if (Array.isArray(element) && key === 'options') {
                    content.push(getTableRow(key, element.map(itm => <div key={itm['uid']}>{itm['uid']}
                        <small> ({itm['displayText']})</small></div>)));
                } else if (key === 'conditions' || key === 'dataSource') {
                    content.push(getTableRow(key, element.map(itm => <div key={itm}>{itm}</div>)));
                } else if (key === 'implement') {
                    content.push(getTableRow(key, printKeyValues(element, true)));
                } else if (key === 'subMeasure') {
                    content.push(getTableRow(key, printKeyValues(element[0], true)));
                } else if (key === 'energyComparison') {
                    let innerContent = element.map(itm => getAccordionItemEnergyAnalysis(itm['displayText'], itm));
                    content.push(getTableRow(key, <Accordion>{innerContent}</Accordion>));
                } else if (key === 'subOption') {
                    let innerContent = element.map((itm, idx) => getAccordionItemSubObtionMeasure(itm, idx));
                    content.push(<Accordion key={key} alwaysOpen={true}>{innerContent}</Accordion>);
                } else {
                    if (countEntriesKeys.indexOf(key) !== -1) {
                        content.push(getTableRow(key, element.length + " elements"));
                    } else {
                        content.push(getTableRow(key, element.toString(), '', useLargeLabelCol));
                    }
                }
            });
        }

        return content;
    }

    function getTableRow(label, content, classNames = '', useLargeLabelCol = false) {
        return <Row key={label} className={classNames !== '' ? classNames : ''}>
            <Col xs={useLargeLabelCol ? 4 : 3} key={'label'}>
                <strong>{label}</strong>
            </Col>
            <Col xs={useLargeLabelCol ? 8 : 9} className={'text-wrap'} key={'value'}>
                {content}
            </Col>
        </Row>;
    }

    function getAccordionItemEnergyAnalysis(title, obj) {
        let innerContent = [];
        for (let key of Object.keys(obj)) {
            if (key === "displayText")
                continue;
            innerContent.push(getTableRow(key, printKeyValues(obj[key], true)));
        }
        return <Accordion.Item key={title} itemID={title} eventKey={title}>
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>{innerContent}</Accordion.Body>
        </Accordion.Item>;
    }

    function getAccordionItemSubObtionMeasure(obj, idx) {
        let innerContent = [];
        const title = `${obj['uid']} (${obj['displayText']})`;
        const relevantAttrs = ['uid', 'totalEffect', 'effects', 'cost', 'subOptionWidget'];
        let accKey = obj['uid'];
        if (obj['uid'] === '_bvt_0') {
            accKey += idx;
        }
        for (let key of relevantAttrs) {
            const val = obj[key];
            if (key === 'cost') {
                const keys = Object.keys(val);
                innerContent.push(getTableRow(key, keys.map(itm => <p key={itm}>{itm}={val[itm]}</p>)));
            } else {
                innerContent.push(getTableRow(key, val.toString()));
            }
        }
        return <Accordion.Item key={accKey} itemID={accKey} eventKey={accKey}>
            <Accordion.Header key={'head'}>{title}</Accordion.Header>
            <Accordion.Body key={'body'}>{innerContent}</Accordion.Body>
        </Accordion.Item>;
    }

    function getAccordionItem(title, obj) {
        return <Accordion.Item key={title} itemID={title} eventKey={title}>
            <Accordion.Header key={'head'}>{title}</Accordion.Header>
            <Accordion.Body key={'body'}>{printKeyValues(obj)}</Accordion.Body>
        </Accordion.Item>;
    }

    function printAttrs() {
        const title = 'attrs';
        let content = <i>undefined</i>;
        if (houseAttrs) {
            content = <Accordion key={title}>
                {getAccordionItem(title, houseAttrs)}
            </Accordion>;
        }
        return getTableRow(title, content);
    }

    function printScene() {
        const title = 'scene';
        let content = <i>undefined</i>;
        if (scene) {
            content = <Accordion key={title}>
                {getAccordionItem(title, scene)}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printFirstQuery() {
        const title = 'firstQuery';
        let content = <i>undefined</i>;
        if (firstQuery.length > 0) {
            content = <Accordion key={title}>
                {firstQuery.map(item => getAccordionItem(item.parameter + " (" + item.displayText + ")", item))}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printDefineTargets() {
        const title = 'defineTargets';
        let content = <i>undefined</i>;
        if (defineTargets.length > 0) {
            content = <Accordion key={title}>
                {defineTargets.map(item => getAccordionItem(item.parameter + " (" + item.displayText + ")", item))}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printSecondQuery() {
        const title = 'secondQuery';
        let content = <i>undefined</i>;
        if (secondQuery.length > 0) {
            content = <Accordion key={title}>
                {secondQuery.map(item => getAccordionItem(item.parameter + " (" + item.displayText + ")", item))}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printEnergyAnalysis() {
        const title = 'energyAnalysis';
        let content = <i>undefined</i>;
        if (energyAnalysis) {
            content = <Accordion key={title}>
                <Accordion.Item key={title} itemID={title} eventKey={title}>
                    <Accordion.Header>{title}</Accordion.Header>
                    <Accordion.Body>{printKeyValues(energyAnalysis)}</Accordion.Body>
                </Accordion.Item>
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printTuneTargets() {
        const title = 'tuneTargets';
        let content = <i>undefined</i>;
        if (tuneTargets.length > 0) {
            content = <Accordion key={title}>
                {tuneTargets.map(item => getAccordionItem(item.parameter + " (" + item.displayText + ")", item))}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    function printScenario() {
        const title = 'scenario';
        let content = <i>todo definition</i>;
        return getTableRow(title, content, 'mt-3');
    }

    function printMeasures() {
        const title = 'measures';
        let content = <i>undefined</i>;
        if (measures.length > 0) {
            content = <Accordion key={title}>
                {measures.map(item => getAccordionItem(item.parameter + " (" + item.displayText + ")", item))}
            </Accordion>;
        }
        return getTableRow(title, content, 'mt-3');
    }

    return <>
        {printAttrs()}
        {printScene()}
        {printFirstQuery()}
        {printSecondQuery()}
        {printDefineTargets()}
        {printEnergyAnalysis()}
        {printTuneTargets()}
        {printMeasures()}
        {printScenario()}
    </>;


};