import React, {useEffect} from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import {useSelector} from "react-redux";
import {selectDataLoading, selectDataSelectedAddress} from "../../reducers/dataSelectors";
import {useNavigate} from "react-router";
import {logMessage} from "../../lib/logging";

export const TaxSavings = (props) => {
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const loading = useSelector(selectDataLoading);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedAddress === undefined && !loading.first) {
            logMessage('no address selected - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [selectedAddress, loading, navigate]);
    return <>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>Details Steuerersparnis</PageTitle>
            <p>Placeholder</p>
        </Container>
    </>;
};