import React from 'react';
import Spinner from "react-bootstrap/Spinner";

export const LoadingMessage = ({children}) => {
    return (
        <div>
            <Spinner className={'me-3'} animation="border" size={"sm"}/>
            {children}
        </div>
    );
};