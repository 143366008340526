import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useSelector} from "react-redux";
import {selectDataLoaded, selectDataLoading} from "../../reducers/dataSelectors";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
export const LoadingDebug = () => {
    const loading = useSelector(selectDataLoading);
    const loaded = useSelector(selectDataLoaded);

    // const checkIcon = icon({name: 'check', family: 'classic', style: 'solid'})
    // <FontAwesomeIcon icon={faEnvelope}/>
    return <>
        <Row className={'mt-3'} key={'loading'}>
            <Col xs={4}>Loading</Col>
            <Col>First: {loading.first.toString()} </Col>
            <Col>Second: {loading.second.toString()}</Col>
            <Col>Third: {loading.third.toString()}</Col>
            <Col>Fourth: {loading.fourth.toString()}</Col>
        </Row>
        <Row className={'mt-3 mb-5'} key={'loaded'}>
            <Col xs={4}>Loaded</Col>
            <Col>First: {loaded.first.toString()}</Col>
            <Col>Second: {loaded.second.toString()}</Col>
            <Col>Third: {loaded.third.toString()}</Col>
            <Col>Fourth: {loaded.fourth.toString()}</Col>
        </Row>
    </>;
};