import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import React from "react";

export default function PartnerCard({image, name = '', height='100px', marginTop=''}) {

    return <Col xs={12} sm={6} lg={4} xl={3}  className={'mb-3'}>
        <Card className={'text-center'} style={{'height': '150px'}}>
            <Card.Body>
                <Card.Text className={'justify-content-center '}>
                    <img alt={name} src={image} style={{'height': height, 'maxWidth': '200px', 'marginTop': marginTop}} />
                </Card.Text>
            </Card.Body>
        </Card>
    </Col>;

}