import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {FaEdit} from "react-icons/fa";

export const DetailFormRow = ({
                                  showEdit = false,
                                  children,
                                  label,
                                  additionalClasses = ''}) => {
    let classes = `d-flex mb-4 mb-sm-0 justify-content-start justify-content-sm-end ${additionalClasses}`;
    return (<>
            <Row>
                <Col className={additionalClasses}>{label}</Col>
                <Col xs={12} sm={6} className={classes}>
                    {children}
                    {showEdit ? <Col className={'ms-2'} style={{'marginTop': '-2px'}} xs={"auto"}>
                        <FaEdit style={{'cursor': 'pointer'}}/>
                    </Col> : ""}
                </Col>
            </Row>
        </>
    );
};