import React, {useEffect, useRef} from 'react';
import {ProgressBar} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {selectDataLoading, selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {settingsSetMeasureLoading} from "../../reducers/settingsActions";

export const LoadingMeasures = () => {

    const dispatch = useDispatch();
    const loading = useSelector(selectDataLoading);
    const measureLoadingState = useSelector(state => state.settings.measureLoadingState);

    const solarElement = useSelector(state => selectElementFromAnywhere(state, '_solarSystem_installedArea'));
    const pvElement = useSelector(state => selectElementFromAnywhere(state, '_pvSystem_installedPower'));
    const hasSolar = !!(solarElement && solarElement.defineValue === 'true');
    const hasPV = !!(pvElement && pvElement.defineValue === 'true');

    const measureCounter = useRef(measureLoadingState);
    measureCounter.current = measureLoadingState;

    const currentLoadingFourth = useRef(loading.fourth);
    currentLoadingFourth.current = loading.fourth;

    const useRandomTick = true;

    useEffect(() => {
        if (measureLoadingState === 0) {
            createTimeout();
        }
    });

    const textStart = [
        "Wohnfläche berechnen",
        "Gebäudehülle analysieren",
        "Gebäude-Luft-Infiltration analysieren",
        "Ausstattung analysieren",
        "Ausbaustandard berechnen",
        "Heizungstyp auswählen",
        "Heizungstyp optimieren",
        "Heizungstyp optimieren"
    ];
    const textSolar = [
        "Solaranlage analysieren",
        "Solaranlage optimieren"
    ];
    const textPV = [
        "Photovoltaik analysieren",
        "Photovoltaik optimieren"
    ];
    const textEnd = [
        "Dach analysieren",
        "Beleuchtung analysieren",
        "Lüftung analysieren",
        <>CO<sub>2</sub> reduzieren</>,
        "Wohnkomfort erhöhen",
        "Langfristige Werterhaltung",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren",
        "Energieverbrauch optimieren"
    ];

    const texts = [...textStart];
    if (!hasSolar) texts.push(...textSolar);
    if (!hasPV) texts.push(...textPV);
    texts.push(...textEnd);

    const updateMeasureLoadingState = (value) => {
        dispatch(settingsSetMeasureLoading({value: value}));
    };

    const incrementCurrent = () => {
        let myCurrent = measureCounter.current;
        if (myCurrent < 100) {
            let newVal = myCurrent + 1 + getRandomNumber(2);
            if (newVal > 100) newVal = 100;
            updateMeasureLoadingState(newVal);
            measureCounter.current = newVal;
            createTimeout();
        } else if (myCurrent === 100) {
            updateMeasureLoadingState(101);
        }
    };

    const getRandomNumber = (max) => {
        return Math.floor(Math.random() * max) + 1;
    };

    const getBaseTimeout = () => {
        let baseTimeout = 400;
        if (hasPV) baseTimeout -= 90;
        if (hasSolar) baseTimeout -= 90;
        return baseTimeout;
    };

    const createTimeout = () => {
        let timeoutDuration;

        if (useRandomTick) {
            if (measureCounter.current === 100) {
                timeoutDuration = 800;
            } else {
                if (currentLoadingFourth.current) {
                    timeoutDuration = (getRandomNumber(5)) * getBaseTimeout();
                } else {
                    timeoutDuration = (getRandomNumber(2)) * 125;
                }
            }
        } else {
            if (currentLoadingFourth.current) {
                timeoutDuration = getBaseTimeout();
            } else {
                timeoutDuration = 40;
            }
        }
        setTimeout(incrementCurrent, timeoutDuration);
    };

    const getCurrentText = () => {
        let currentText = 'Massnahmen werden geladen ...';
        let customText = '';
        if (measureLoadingState === 100) {
            customText = 'Energieverbrauch optimieren';
        } else {
            if (measureLoadingState <= 0) {
                currentText = '';
            } else {
                let myOpt = Math.floor((measureLoadingState / 100) * texts.length);
                customText = texts[myOpt];
            }
        }

        return <p className={'mt-3'}>{currentText} {customText}</p>;
    };

    // in case the animation is already completed, but the fourth query is still loading,
    // display the 100% progress bar and wait
    if (measureLoadingState > 100 && loading.fourth) {
        return <>
            <p className={'mt-3'}>Massnahmen werden geladen ...</p>
            <ProgressBar now={100} animated={false} label={`100 %`} variant={"success"}/>
        </>;
    }

    if(measureLoadingState === -1) return "";

    return <>
        {getCurrentText()}
        <ProgressBar now={measureLoadingState}
                     animated={measureLoadingState >= 0 && measureLoadingState < 100}
                     label={`${measureLoadingState} %`}
                     variant={measureLoadingState === 100 ? "success" : 'primary'}
        />
    </>;
};