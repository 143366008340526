import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import './DetailFormToggleElement.css';
import {useDispatch, useSelector} from "react-redux";
import {selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataEnableParameter,} from "../../reducers/dataActions";
import {getDefineLabel} from "../../lib/display-utils";
import {DetailFormRow} from "./DetailFormRow";

export const DetailFormToggleElement = ({
                                            parameterName = 'unknown',
                                            textColor = ''
                                        }) => {

    const myElement = useSelector(state => selectElementFromAnywhere(state, parameterName));
    const dispatch = useDispatch();

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (myElement) {
            setIsChecked(myElement.defineValue === 'true');
        }
    }, [myElement]);

    const handleSwitchChange = (e) => {
        setIsChecked(e.target.checked);
        dispatch(dataEnableParameter({
            parameter: parameterName,
            enable: e.target.checked ? 'true' : 'false'
        }));

    };

    const label = getDefineLabel(myElement);

    if (!myElement) return "";

    return (
        <DetailFormRow label={label} showEdit={false} additionalClasses={textColor}>
            <Form.Check
                type="switch"
                id={`detailswitch-${parameterName}`}
                label={isChecked ? 'Ja' : 'Nein'}
                checked={isChecked}
                onChange={handleSwitchChange}
            />
        </DetailFormRow>
    );
};