import {useDispatch, useSelector} from "react-redux";
import {selectConditionalElementFromAnywhere, selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataUpdateValue,} from "../../reducers/dataActions";
import React, {useEffect, useRef, useState} from "react";
import {DetailFormRow} from "./DetailFormRow";
import {getItemDisplayValue, getItemValue, getLabel, isInvalid} from "../../lib/display-utils";
import {FaEdit} from "react-icons/fa";
import {Form, InputGroup} from "react-bootstrap";

export const DetailFormDropdownElement = ({
                                              parameterName = 'unknown',
                                              textColor = '',
                                              condition = '',
                                              conditionValue = false
                                          }) => {

    const myElement = useSelector(state => selectConditionalElementFromAnywhere(state, parameterName));
    const conditionElement = useSelector(state => selectElementFromAnywhere(state, condition));
    const dispatch = useDispatch();

    const dropdownRef = useRef(null);

    const value = getItemValue(myElement);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [editableValue, setEditableValue] = useState(value);

    useEffect(() => {
        setEditableValue(value);
    }, [value]);

    const isSupportedWidgetType = (item) => {
        switch (item.widgetType) {
            case 'guiTypeDropdown':
            case 'guiTypeRadio':
            case 'guiTypeRadioHint':
                return true;
            default:
                return false;
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleValueClick = () => {
        setIsDropdownOpen(true);
    };

    const handleInputChange = (e) => {
        setEditableValue(e.target.value);
        dispatch(dataUpdateValue({
            parameter: myElement.parameter,
            value: e.target.value
        }));
        setIsDropdownOpen(false);
    };

    const label = getLabel(myElement);
    let content = '';

    if (!myElement) return ""; // return <InvalidElement name={parameterName} useContainer={false}></InvalidElement>

    const dummyElement = {
        widgetType: myElement.widgetType,
        options: myElement.options,
        value: editableValue,
        parameter: myElement.parameter
    };
    const valueInvalid = isInvalid(dummyElement);
    const displayValue = getItemDisplayValue(dummyElement);

    const getFormControl = (item) => {

        return <Form.Select
            style={{textAlign: 'right', textAlignLast: 'right', direction: 'rtl', paddingRight: '2.75rem'}} size={'sm'}
            aria-label={label}
            ref={dropdownRef}
            onChange={handleInputChange}
            value={item.value}>
            {item.options.map(option => {
                return <option key={option.uid} value={option.uid}>{option.displayText}</option>;
            })}
        </Form.Select>;
    };

    content = <div>{isDropdownOpen ? getFormControl(myElement) : (
        <div className={'d-flex justify-content-center align-content-center cursor-pointer'} onClick={handleValueClick}>
            <InputGroup>
                <span onClick={handleValueClick}>{displayValue}</span>
                <InputGroup.Text className={'ps-3 pe-0'}
                                 style={{background: 'none', border: 'none'}}>
                    <FaEdit className={textColor} size={14}/>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )}
    </div>;

    if (!isSupportedWidgetType(myElement)) {
        content = <span className={'text-danger small'}>unsupported type {myElement.widgetType}</span>;
    }

    if (condition !== '' && conditionElement) {
        const conditionValueParsed = conditionElement.defineValue === 'true';
        if (conditionValueParsed !== conditionValue) {
            return "";
        }
    }

    return <>
        <DetailFormRow label={label}
                       showEdit={false}
                       additionalClasses={valueInvalid ? 'text-danger' : textColor}>
            {content}
        </DetailFormRow>
    </>;
};