import _ from "lodash";
import {DEFINE_LABELS, PARAM_LABELS} from "../config";
import {logError} from "./logging";


export const getLabel = (item) => {
    if (!item) return 'undefined item';
    let label = item.displayText;
    if (_.has(PARAM_LABELS, item.parameter)) {
        label = PARAM_LABELS[item.parameter];
    }
    return label;
};

export const getDefineLabel = (item) => {
    if (!item) return 'undefined item';
    let label = item.defineText;
    if (_.has(DEFINE_LABELS, item.parameter)) {
        label = DEFINE_LABELS[item.parameter];
    }
    return label;
};

export const getItemDisplayValue = (item) => {
    if (!item) return undefined;
    switch (item.widgetType) {
        case 'guiTypeStepper':
        case 'guiTypeNoUiSlider':
        case 'guiTypeNoUiSliderCheck':
            return item.value;
        case 'guiTypeDropdown':
        case 'guiTypeRadio':
        case 'guiTypeRadioHint':
            if (item.options) {
                const selectedValue = item.options.find(x => x.uid === item.value);
                if (selectedValue) {
                    return selectedValue.displayText;
                } else {
                    return '';
                }
            } else return '';
        default:
            logError(`unhandled widget type ${item.widgetType}`);
            return '?';
    }
};

export const getItemValue = (item) => {
    if (!item) return 'undefined';
    switch (item.widgetType) {
        case 'guiTypeStepper':
        case 'guiTypeNoUiSlider':
        case 'guiTypeNoUiSliderCheck':
        case 'guiTypeDropdown':
        case 'guiTypeRadio':
        case 'guiTypeRadioHint':
            return item.value;
        default:
            logError(`unhandled widget type ${item.widgetType}`);
            return '?';
    }
};

export const isInvalid = (element) => {
    if (!element) return true;
    switch (element.widgetType) {
        case 'guiTypeDropdown':
        case 'guiTypeRadio':
        case 'guiTypeRadioHint':
            return false;
        case 'guiTypeStepper':
        case 'guiTypeNoUiSlider':
        case 'guiTypeNoUiSliderCheck': {
            const minValue = Number(element.minValue);
            const maxValue = Number(element.maxValue);
            const parsedValue = Number(element.value);
            return !((minValue <= parsedValue) && (parsedValue <= maxValue));
        }
        default: {
            logError(`widgetType not implemented in isInvalid: ${element.widgetType}`);
            return true;
        }
    }
};