import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useSelector} from "react-redux";
import {selectDataActiveMeasuresBeforeYear} from "../../reducers/dataSelectors";
import {CurrencyDisplay} from "./display/CurrencyDisplay";
import {MeasureSavingsRowElement} from "./MeasureSavingsRowElement";
import {NumberDisplay} from "./display/NumberDisplay";

export const MeasureSavingsRow = ({
                                      maxElements, showHeader = false, showHeaderBorder = false, name,
                                      bold = false, attribute = 'grossInvestment',
                                      showBottomBorder = false, useKilogramValue = false
                                  }) => {


    const startYear = new Date().getFullYear() + 1;
    const maxYear = startYear + maxElements - 1;

    const allMeasures = useSelector(state => selectDataActiveMeasuresBeforeYear(state, maxYear.toString(), false));
    const total = allMeasures.reduce((total, item) => total + Number(item.subMeasure[0].subOption[0].cost[attribute]), Number(0));


    const contentAlign = 'justify-content-start';
    const contentBold = bold ? 'fw-bold' : '';
    const contentClasses = `d-flex ${contentAlign} ${contentBold}`;

    const useColumnColors = true;
    const columnColor = 'bg-light';
    const rowMargin = 'mb-1';

    // let backgroundClasses = 'bg-light';
    // let backgroundClasses = 'bg-dark bg-opacity-10';
    let backgroundClasses = '';
    // if (bold) backgroundClasses = `bg-dark bg-opacity-10`;

    const getHeaders = () => {
        if (!showHeader) return "";
        let cols = [];
        for (let i = startYear; i < (startYear + maxElements); i++) {
            cols.push(<Col key={i}>
                <h4 className={`d-flex ${contentAlign} mt-2`}>{i !== (startYear + maxElements - 1) ? i : "Total"}</h4>
            </Col>);
        }
        return <Row className={'mt-2'}>{cols}</Row>;
    };
    const getHeaderBorder = () => {
        if (!showHeaderBorder) return "";
        return <Row className={'mb-0 border-bottom border-1 border-dark'}>
            <Col/>
        </Row>;
    };


    const getBottomBorder = () => {
        if (!showBottomBorder) return "";
        return <Row className={`${rowMargin} border-bottom border-1 border-dark`}>
            <Col/>
        </Row>;
    };


    const getContentDescription = () => {
        const cols = [];
        for (let i = startYear; i < (startYear + maxElements - 1); i++) {
            if (useColumnColors) {
                cols.push(<Col key={i} className={`${contentClasses} ${i % 2 === 0 ? columnColor : ''}`}/>);
            } else {
                cols.push(<Col/>);
            }
        }
        return <Row className={backgroundClasses}>
            {cols}
            <Col key={'total'} xs={{span: 2}}
                 className={`d-flex ${contentAlign} ${contentBold} small`}>{name}</Col>
        </Row>;
    };

    const getContentRow = () => {
        const cols = [];
        for (let i = startYear; i < (startYear + maxElements - 1); i++) {
            cols.push(<Col key={i} className={`${contentClasses} ${useColumnColors && i % 2 === 0 ? columnColor : ''}`}>
                <MeasureSavingsRowElement year={i} attribute={attribute}/>
            </Col>);
        }
        return <>

            <Row className={`${backgroundClasses} ${rowMargin}`}>
                {cols}
                <Col key={'total'} className={`${contentClasses}`}>
                    {attribute === 'CO2Savings' ?
                        <NumberDisplay suffix={'kg'}>{total}</NumberDisplay> :
                        <CurrencyDisplay>{total}</CurrencyDisplay>
                    }

                </Col>
            </Row>

        </>;
    };

    return (
        <>
            {getHeaders()}
            {getHeaderBorder()}
            {getContentDescription()}
            {getContentRow()}
            {getBottomBorder()}
        </>
    );
};