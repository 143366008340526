import Breadcrumb from "react-bootstrap/Breadcrumb";
import {useLocation} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import React from "react";
import {useSelector} from "react-redux";
import {selectDataLoaded, selectDataLoading} from "../../reducers/dataSelectors";

export const NavBreadcrumb = () => {
    let location = useLocation();
    const loading = useSelector(selectDataLoading);
    const loaded = useSelector(selectDataLoaded);

    const enableBreakpointHints = false;

    return (
        <Breadcrumb>
            {breadcrumbItem('/auswahl', 'Gebäudeauswahl')}
            {breadcrumbItem('/dateneingabe', 'Dateneingabe', loaded.third || loading.first || loading.second || loading.third)}
            {breadcrumbItem('/details', 'Detaildaten', loaded.third)}
            {breadcrumbItem('/situation', 'Situationsanalyse', loaded.third)}
            {breadcrumbItem('/massnahmen', 'Massnahmen', loading.fourth || loaded.fourth)}
            {breadcrumbItem('/massnahmenplanung', 'Planung', loaded.fourth)}
            {/*{breadcrumbItem('/fördergelder', 'Fördergelder', loaded.fourth)}*/}
            {/*{breadcrumbItem('/finanzplanung', 'Finanzplanung', loaded.fourth)}*/}
            {showBreakpoints()}
        </Breadcrumb>
    );

    function showBreakpoints() {
        if (!enableBreakpointHints) return "";
        return <>
            <div className={'ms-3'}>
            <p className={'d-block d-sm-none'}>XS</p>
            <p className={'d-none d-sm-block d-md-none'}>SM</p>
            <p className={'d-none d-md-block d-lg-none'}>MD</p>
            <p className={'d-none d-lg-block d-xl-none'}>LG</p>
            <p className={'d-none d-xl-block d-xxl-none'}>XL</p>
            <p className={'d-none d-xxl-block'}>XXL</p>
            </div>
        </>;
    }

    function breadcrumbItem(link, text, display = true) {
        if (!display) return '';
        if (location.pathname === link) {
            return <Breadcrumb.Item active>
                {text}
            </Breadcrumb.Item>;
        }
        return <LinkContainer to={link}>
            <Breadcrumb.Item>{text}</Breadcrumb.Item>
        </LinkContainer>;
    }
};