import {useSelector} from "react-redux";
import {selectDataAttrs, selectDataScene} from "../../reducers/dataSelectors";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


export const HouseAddressTitle = (props) => {

    const attrs = useSelector(selectDataAttrs);
    const scene = useSelector(selectDataScene);

    if (attrs) {
        return <Row>
            <Col>
                <h3><strong>{attrs.strname1}<br/>{attrs.plz4} {attrs.gdename}</strong></h3>
                {scene ? <h5>{parseFloat(scene.baseAltitude).toFixed(0)} m ü.M.</h5> : ""}
                <p>Gebäudehöhe: {parseFloat(scene.buildingHeight).toFixed(1)} m</p>
            </Col>
        </Row>;
    } else return <><p className={'text-danger'}>House Attributes not set</p> </>;
};