import React from 'react';
import Container from "react-bootstrap/Container";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {PageTitle} from "../layout/PageTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Disclaimer = (props) => {
    return <>
        <NavBreadcrumb/>
        <Container>
            <PageTitle>Disclaimer</PageTitle>
            <Row>
                <Col xs={12} xl={8} className={'mb-5'}>
                    <div className={'mt-3'}>
                        <h5>
                            <strong>Rechtliche Hinweise</strong>
                        </h5>
                        <p>Die zugrundeliegenden Berechnungen basieren auf online verfügbaren Daten,
                            Standard-Dimensionierungsregeln aus Schweizer Normen und Annahmen aufgrund statistischer
                            Erhebungen. Die angezeigten Vorschläge, Ergebnisse und insbesondere Kostenangaben können je
                            nach Rahmenbedingungen eines Gebäudes von den tatsächlichen Werten (merklich) abweichen. Die
                            eingerechneten Steuerabzüge und Förderbeiträge erfolgen zeitverzögert. Weder
                            myEnergySolutions noch unsere Partner übernehmen Gewähr für die Richtigkeit
                            der Ergebnisse. Vor einem Investitionsentscheid empfehlen wir den Beizug einer
                            Fachperson.</p>
                    </div>
                    <div className={'mt-5'}>
                        <h5>
                            <strong>Datenschutz</strong>
                        </h5>
                        <p>
                            Die Projektdaten der User werden nur auf deren ausdrücklichen Wunsch gespeichert. Dies
                            ermöglicht ein späteres Weiterbearbeiten des Projektes. Die Projektdaten werden bei einer
                            ausdrücklichen Berartungsanfrage ausschliesslich an den Anbieter des Websdienstes geschickt.
                            Sämtliche Daten sind durch Verschlüsselung zusätzlich durch unbefugten Zugriff geschützt.
                            Der Anbieter des Websdienstes wie auch myEnergySolutions nutzen die Daten nur in der durch
                            den User autorisierten Weise.
                        </p>
                    </div>
                    <div className={'mt-5'}>
                        <h5>
                            <strong>Impressum</strong>
                        </h5>
                        <p>
                            myEnergySolutions GmbH<br/>
                            Technologiepark Wetzikon<br/>
                            Frohbergstrasse 12c<br/>
                            CH-8620 Wetzikon ZH
                        </p>
                        <p>
                            <a className={'link'} href={'https://myenergyguide.ch/res/663/images/myEnergySolutions_agb.pdf'}
                               target={'_blank'} rel={'noreferrer'}>Allgemeine Geschäftsbedingungen</a>
                        </p>
                    </div>
                    <div className={'mt-5'}>
                        <h5>
                            <strong>Credits</strong>
                        </h5>
                        <p>
                            Gebäude- und Wohnungsregister: Bundesamt für Statistik<br/>
                            Adress-Zuordnung: swisstopo<br/>
                            3D-Gebäudedaten: Luucy AG<br/>
                            Wetterdaten: Meteonorm 7.2<br/>
                            Strompreise: Elcom<br/>
                            Einspeisung: PVTarif<br/>
                            Energiekosten: EnergieSchweiz<br/>
                            Graue Energie: Bundesamt für Bauten und Logistik
                        </p>
                    </div>
                </Col>
            </Row>

        </Container>
    </>;
};