import {createReducer} from "@reduxjs/toolkit";
import {addressSelect} from "./addressActions";
import {logError, logMessage} from "../lib/logging";
import {
    dataEnableMeasureGroup,
    dataEnableParameter, dataLoadFifthQuery, dataLoadFifthQueryFailure, dataLoadFifthQuerySuccess,
    dataLoadFirstQuery,
    dataLoadFirstQueryFailure,
    dataLoadFirstQuerySuccess,
    dataLoadFourthQuery,
    dataLoadFourthQueryFailure,
    dataLoadFourthQuerySuccess,
    dataLoadSecondQuery,
    dataLoadSecondQueryFailure,
    dataLoadSecondQuerySuccess,
    dataLoadThirdQuery,
    dataLoadThirdQueryFailure,
    dataLoadThirdQuerySuccess,
    dataMeasureChangeYear, dataMeasureOptionUpdateSelection,
    dataMeasureSelectOption, dataUpdateTuneTargetValue,
    dataUpdateValue
} from "./dataActions";

export const dataEmptyError = {hasError: false, message: "", detail: "", code: ""};

const initialState = {
    selectedAddress: undefined,
    userData: undefined,
    firstQuery: [],
    attrs: undefined,
    scene: undefined,
    defineTargets: [],
    energyAnalysis: undefined,
    secondQuery: [],
    tuneTargets: [],
    measures: [],
    scenario: [],
    error: getDataErrorObject(),
    loaded: {first: false, second: false, third: false, fourth: false, fifth: false},
    loading: {first: false, second: false, third: false, fourth: false, fifth: false},
};

export function getDataErrorObject(hasError = false, message = "",
                                   detail = "", code = "") {
    const obj = Object.assign({}, dataEmptyError);
    obj.code = code;
    obj.detail = detail;
    obj.message = message;
    obj.hasError = hasError;
    return obj;
}

export const findParameterLocation = (state, parameter) => {
    let myQuery = undefined;
    let itm = state.firstQuery.find(itm => itm.parameter === parameter);
    if (itm) myQuery = 'first';
    else {
        itm = state.secondQuery.find(itm => itm.parameter === parameter);
        if (itm) myQuery = 'second';
        else {
            itm = state.defineTargets.find(itm => itm.parameter === parameter);
            if (itm) myQuery = 'defineTargets';
        }
    }
    if (!myQuery) {
        logMessage(`param ${parameter} NOT FOUND in [first, second, defineTargets]`);
    }
    return myQuery;
};

const dataReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(addressSelect, (state, action) => {
            state.selectedAddress = action.payload;
            if (action.payload === undefined) {
                state.loaded = {first: false, second: false, third: false, fourth: false};
                state.attrs = undefined;
                state.scene = undefined;
                state.firstQuery = [];
                state.userData = undefined;
                state.defineTargets = [];
                state.energyAnalysis = undefined;
                state.secondQuery = [];
            }
        })
        .addCase(dataLoadFirstQuery, (state, action) => {
            state.loading.first = true;
            state.loaded.first = false;
            state.firstQuery = Object.assign([]);
            state.attrs = undefined;
            state.scene = undefined;
            state.userData = undefined;
            state.error = getDataErrorObject();
        })
        .addCase(dataLoadFirstQuerySuccess, (state, action) => {
            state.loading.first = false;
            state.loaded.first = true;
            state.error = getDataErrorObject();
            state.firstQuery = [...action.payload['firstQuery']];
            state.attrs = Object.assign({}, action.payload['attrs']);
            state.scene = Object.assign({}, action.payload['scene']);
            state.userData = Object.assign({}, action.payload['userData']);
        })
        .addCase(dataLoadFirstQueryFailure, (state, action) => {
            state.loading.first = false;
            state.loaded.first = false;
            state.error = action.payload;
            state.firstQuery = Object.assign([]);
            state.attrs = undefined;
            state.scene = undefined;
            state.userData = undefined;
        })
        .addCase(dataLoadSecondQuery, (state, action) => {
            state.loading.second = true;
            state.loaded.second = false;
        })
        .addCase(dataLoadSecondQuerySuccess, (state, action) => {
            state.loading.second = false;
            state.loaded.second = true;
            state.error = getDataErrorObject();
            state.firstQuery = [...action.payload['firstQuery']];
            state.attrs = Object.assign({}, action.payload['attrs']);
            state.defineTargets = [...action.payload['defineTargets']];
            state.energyAnalysis = Object.assign({}, action.payload['energyAnalysis']);
        })
        .addCase(dataLoadSecondQueryFailure, (state, action) => {
            state.loading.second = false;
            state.loaded.second = false;
            state.error = action.payload;
        })
        .addCase(dataLoadThirdQuery, (state, action) => {
            state.loading.third = true;
            state.loaded.third = false;
        })
        .addCase(dataLoadThirdQuerySuccess, (state, action) => {
            state.loading.third = false;
            state.loaded.third = true;
            state.error = getDataErrorObject();
            state.attrs = Object.assign({}, action.payload['attrs']);
            state.firstQuery = [...action.payload['firstQuery']];
            state.defineTargets = [...action.payload['defineTargets']];
            state.secondQuery = [...action.payload['secondQuery']];
        })
        .addCase(dataLoadThirdQueryFailure, (state, action) => {
            state.loading.third = false;
            state.loaded.third = false;
            state.error = action.payload;
        })
        .addCase(dataLoadFourthQuery, (state, action) => {
            state.loading.fourth = true;
            state.loaded.fourth = false;
        })
        .addCase(dataLoadFourthQuerySuccess, (state, action) => {
            state.loading.fourth = false;
            state.loaded.fourth = true;
            state.error = getDataErrorObject();
            state.attrs = Object.assign({}, action.payload['attrs']);
            state.energyAnalysis = Object.assign({}, action.payload['energyAnalysis']);
            state.tuneTargets = [...action.payload['tuneTargets']];
            state.scenario = [...action.payload['scenario']];
            state.measures = [...action.payload['measures']];
            const firstKeys = state.firstQuery.map(itm => itm['parameter']);
            const secondKeys = state.secondQuery.map(itm => itm['parameter']);
            let firstAdded = 0;
            let secondAdded = 0;
            for (let obj of action.payload.fourthQuery) {
                const parameter = obj['parameter'];
                const firstIndex = firstKeys.indexOf(parameter);
                const secondIndex = secondKeys.indexOf(parameter);
                if (firstIndex !== -1) {
                    state.firstQuery[firstIndex] = Object.assign({}, obj);
                    firstAdded += 1;
                }
                if (secondIndex !== -1) {
                    state.secondQuery[secondIndex] = Object.assign({}, obj);
                    secondAdded += 1;
                }
            }
            if (firstKeys.length !== firstAdded) {
                logError(`4th query: consolidation of firstQuery obj mismatch, expected: ${firstKeys.length} actual: ${firstAdded}`);
            }
            if (secondKeys.length !== secondAdded) {
                logError(`4th query: consolidation of secondQuery obj mismatch, expected: ${secondKeys.length} actual: ${secondAdded}`);
            }
        })
        .addCase(dataLoadFourthQueryFailure, (state, action) => {
            state.loading.fourth = false;
            state.loaded.fourth = false;
            state.error = action.payload;
        })
        .addCase(dataLoadFifthQuery, (state, action) => {
            state.loading.fifth = true;
            state.loaded.fifth = false;
        })
        .addCase(dataLoadFifthQuerySuccess, (state, action) => {
            state.loading.fifth = false;
            state.loaded.fifth = true;
            state.error = getDataErrorObject();
            state.attrs = Object.assign({}, action.payload['attrs']);
            state.energyAnalysis = Object.assign({}, action.payload['energyAnalysis']);
            state.tuneTargets = [...action.payload['tuneTargets']];
            state.measures = [...action.payload['measures']];
            const firstKeys = state.firstQuery.map(itm => itm['parameter']);
            const secondKeys = state.secondQuery.map(itm => itm['parameter']);
            let firstAdded = 0;
            let secondAdded = 0;
            for (let obj of action.payload.fourthQuery) {
                const parameter = obj['parameter'];
                const firstIndex = firstKeys.indexOf(parameter);
                const secondIndex = secondKeys.indexOf(parameter);
                if (firstIndex !== -1) {
                    state.firstQuery[firstIndex] = Object.assign({}, obj);
                    firstAdded += 1;
                }
                if (secondIndex !== -1) {
                    state.secondQuery[secondIndex] = Object.assign({}, obj);
                    secondAdded += 1;
                }
            }
            if (firstKeys.length !== firstAdded) {
                logError(`5th query: consolidation of firstQuery obj mismatch, expected: ${firstKeys.length} actual: ${firstAdded}`);
            }
            if (secondKeys.length !== secondAdded) {
                logError(`5th query: consolidation of secondQuery obj mismatch, expected: ${secondKeys.length} actual: ${secondAdded}`);
            }
        })
        .addCase(dataLoadFifthQueryFailure, (state, action) => {
            console.log('5th query error', action.payload);
            state.loading.fifth = false;
            state.loaded.fifth = false;
            state.error = action.payload;
        })
        .addCase(dataUpdateValue, (state, action) => {
            const location = findParameterLocation(state, action.payload.parameter);
            if (!location) return;
            logMessage(`updating ${location}.${action.payload.parameter} to ${action.payload.value}`);

            switch (location) {
                case 'first': {
                    state.firstQuery = state.firstQuery.map((itm, idx) => {
                        if (itm.parameter === action.payload.parameter) {
                            return {...itm, value: action.payload.value};
                        }
                        return itm;
                    });
                    break;
                }
                case 'second': {
                    state.secondQuery = state.secondQuery.map((itm, idx) => {
                        if (itm.parameter === action.payload.parameter) {
                            return {...itm, value: action.payload.value};
                        }
                        return itm;
                    });
                    break;
                }
                case 'defineTargets': {
                    state.defineTargets = state.defineTargets.map((itm, idx) => {
                        if (itm.parameter === action.payload.parameter) {
                            return {...itm, value: action.payload.value};
                        }
                        return itm;
                    });
                    break;
                }
                default: {
                    console.log(`updateValue: unhandled query type ${location}`);
                    break;
                }
            }
        })
        .addCase(dataEnableParameter, (state, action) => {
            const location = findParameterLocation(state, action.payload.parameter);
            if (!location) return;
            logMessage(`updating ${location}.${action.payload.parameter} enable=${action.payload.enable}`);
            switch (location) {
                case 'first' : {
                    state.firstQuery = state.firstQuery.map((itm) => {
                        if (itm.parameter === action.payload.parameter) {
                            return {...itm, defineValue: action.payload.enable};
                        }
                        return itm;
                    });
                    break;
                }
                case 'second' : {
                    state.secondQuery = state.secondQuery.map((itm) => {
                        if (itm.parameter === action.payload.parameter) {
                            return {...itm, defineValue: action.payload.enable};
                        }
                        return itm;
                    });
                    break;
                }
                default: {
                    console.log(`enableParam: unhandled query type ${location}`);
                }
            }
        })
        .addCase(dataEnableMeasureGroup, (state, action) => {
            logMessage(`updating measure ${action.payload.parameter} selection=${action.payload.value}`);
            state.measures = state.measures.map((itm) => {
                if (itm.parameter === action.payload.parameter) {
                    return {
                        ...itm,
                        subMeasure: [
                            {
                                ...itm.subMeasure[0],
                                selection: action.payload.value,
                            }
                        ]
                    };
                }
                return itm;
            });
        })
        .addCase(dataMeasureSelectOption, (state, action) => {
            logMessage(`updating measure ${action.payload.parameter} option=${action.payload.option}`);
            state.measures = state.measures.map((itm) => {
                if (itm.parameter === action.payload.parameter) {
                    return {
                        ...itm,
                        subMeasure: [
                            {
                                ...itm.subMeasure[0],
                                value: action.payload.option
                            }
                        ]
                    };
                }
                return itm;
            });
        })
        .addCase(dataMeasureOptionUpdateSelection, (state, action) => {
            logMessage(`updating measure ${action.payload.parameter} option=${action.payload.option} selection=${action.payload.selection}`);
            state.measures = state.measures.map((itm) => {
                if (itm.parameter === action.payload.parameter) {
                    return {
                        ...itm,
                        subMeasure: [
                            {
                                ...itm.subMeasure[0],
                                subOption: itm.subMeasure[0].subOption.map(option => {
                                    if(option.uid === action.payload.option) {
                                        return {
                                            ...option,
                                            selection: action.payload.selection
                                        }
                                    }
                                    return option;
                                })
                            }
                        ]
                    };
                }
                return itm;
            });
        })
        .addCase(dataMeasureChangeYear, (state, action) => {
            logMessage(`updating measure ${action.payload.parameter} year=${action.payload.year}`);
            state.measures = state.measures.map((itm) => {
                if (itm.parameter === action.payload.parameter) {
                    return {
                        ...itm,
                        implement: {
                            ...itm.implement,
                            initialYear: action.payload.year
                        }
                    };
                }
                return itm;
            });
        })
        .addCase(dataUpdateTuneTargetValue, (state, action) => {
            logMessage(`updating tuneTarget ${action.payload.parameter} to ${action.payload.value}`);
            state.tuneTargets = state.tuneTargets.map((itm, idx) => {
                if (itm.parameter === action.payload.parameter) {
                    return {...itm, value: action.payload.value};
                }
                return itm;
            });
        });
});

export default dataReducer;


