import React from 'react';
import {useDispatch} from "react-redux";
import Form from "react-bootstrap/Form";
import {dataLoadFifthQuery, dataMeasureSelectOption} from "../../reducers/dataActions";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const MeasureOptions = ({item}) => {

    const dispatch = useDispatch();

    if (!item) return <div className={'text-danger'}>item not set</div>;

    const handleOptionSelected = (event) => {
        const newValue = event.target.value;
        dispatch(settingsSetEventUID({eventUID: newValue}))
        dispatch(settingsSetClientEvent({clientEvent: 'editOption'}))
        dispatch(dataMeasureSelectOption({parameter: item.parameter, option: newValue}));
        dispatch(dataLoadFifthQuery());
    };

    const renderOptions = () => {
        return <Form.Select size={'sm'} onChange={handleOptionSelected} value={item.value}>
            {item.subOption.map((option, idx) => {
                let fontStyle = 'normal';
                let bgColor = '';
                let setDisabled = false;
                let fontWeight = 400;
                let color = 'black';
                if (Object.keys(option).includes('selection')) {
                    // console.log(option.uid, 'selection', option.selection);
                    if (option.selection === '-1') {
                        // setDisabled = true;
                        // fontStyle = 'italic';
                        // bgColor = 'rgba(255,0,0,0.05)';
                        color = 'rgb(109, 109, 109)';
                    }
                    if (option.selection === '2') {
                        fontWeight = 700;
                    }
                    if (option.selection === '1') {
                        color = 'green';
                    }

                }
                // TODO: report bug to stephan
                if (option['uid'] === '_bvt_0' && idx === 1) return ""; // BUGFIX for duplicate bvt_0 entry
                return <option disabled={setDisabled}
                               style={{
                                   fontStyle: fontStyle, backgroundColor: bgColor, fontWeight: fontWeight,
                                   color: color
                               }}
                               key={option.uid}
                               value={option.uid}>{option.displayText}</option>;
            })}
        </Form.Select>;
    };

    return <div>
        {renderOptions()}
    </div>;
};