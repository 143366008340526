import {DEBUG_LOGGING} from '../config/'

export const logMessage = (message, error = false) => {
    if (DEBUG_LOGGING) {
        if (error) return console.error(message)
        console.log(message)
    }
}
export const logError = (message) => {
    logMessage(message, true)
}