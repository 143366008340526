import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useNavigate} from "react-router";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import {selectDataAttrs, selectDataError, selectDataLoading} from "../../reducers/dataSelectors";
import {HouseAddressTitle} from "../data-input/HouseAddressTitle";
import {MainFormElement} from "../data-input/MainFormElement";
import {InputHeader} from "../layout/InputHeader";
import {MainFormToggableElement} from "../data-input/MainFormToggableElement";
import {Link} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {logMessage} from "../../lib/logging";
import {LoadingMessage} from "../layout/LoadingMessage";
import {GeoAdminMapView} from "../data-input/maps/GeoAdminMapView";

export const DataEntry = () => {

    const navigate = useNavigate();

    const loading = useSelector(selectDataLoading);
    // const selectedAddress = useSelector(selectDataSelectedAddress);

    const isLoading = loading.first || loading.second || loading.third || loading.fourth;
    const attrs = useSelector(selectDataAttrs);
    const error = useSelector(selectDataError);

    // let lat, lon, lv95E, lv95N;
    // if(selectedAddress) {
    //     lat = selectedAddress.attrs.lat;
    //     lon = selectedAddress.attrs.lon;
    //     // [lv95E, lv95N] = transformGPS(lon, lat);
    // }

    useEffect(() => {
        if (attrs === undefined && !loading.first) {
            logMessage('address or attrs is undefined - redirect to auswahl');
            navigate('/auswahl');
        }
    }, [attrs, loading, navigate]);

    function getLoadingMessage() {
        const step = loading.first ? "0 %" : loading.second ? "33%" : loading.third ? "66%" : "??";
        return (
            <div>
                <LoadingMessage>
                    <>Daten werden geladen ... <small>{step}</small></>
                </LoadingMessage>
            </div>
        );
    }

    function getErrorMessage() {
        return <div>
            <h3>Error</h3>
            <p>{error.message}</p>
        </div>;
    }

    function getDataForm() {
        if (loading.first === true) return "";
        if (attrs === undefined) return "";
        return <>
            <Row>
                <Col lg={6}>
                    <HouseAddressTitle/>
                    {/*<Row><Col xs={2}>GPS Lon</Col><Col>{lon}</Col></Row>*/}
                    {/*<Row><Col xs={2}>GPS Lat</Col><Col>{lat}</Col></Row>*/}
                    {/*<Row><Col xs={2}>LV95 East</Col><Col>{lv95E}</Col></Row>*/}
                    {/*<Row><Col xs={2}>LV95 North</Col><Col>{lv95N}</Col></Row>*/}
                </Col>
                <Col>
                    {/*<GoogleMapsView show={true}/>*/}
                    <GeoAdminMapView show={true}/>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Row><Col><InputHeader title={'Gebäude-Nutzung'}/></Col></Row>
                    <Row>
                        <Col sm={6}>
                            <MainFormElement parameterName={'_building_constructionYear'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <MainFormElement parameterName={'_building_occupantNumber'}/>
                        </Col>
                        <Col>
                            <MainFormElement parameterName={'_building_livingArea'}/>
                        </Col>
                    </Row>
                    <Row><Col><InputHeader title={'Energie'}/></Col></Row>
                    <Row>
                        <Col>
                            <MainFormElement
                                parameterName={'_energySystem_heating0'}
                                multiRow={false}
                                yearParameter={'_energySystem_heating0Renovation'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MainFormToggableElement
                                parameterName={'_solarSystem_installedArea'}
                                labelOverride={'Solarwärme'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MainFormElement
                                parameterName={'_energySystem_hotwater0'}
                                multiRow={false}
                                yearParameter={''}
                                condition={'_solarSystem_installedArea'}
                                conditionValue={false}
                                labelOverride={'Warmwasser'}
                            />
                            <MainFormElement
                                parameterName={'_energySystem_hotwater1'}
                                multiRow={false}
                                yearParameter={''}
                                condition={'_solarSystem_installedArea'}
                                conditionValue={true}
                                labelOverride={'Warmwasser'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MainFormToggableElement
                                parameterName={'_pvSystem_installedPower'}
                                labelOverride={'Photovoltaik'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MainFormToggableElement parameterName={'Batteriespeicher'}/>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row><Col><InputHeader title={'Mobilität'}/></Col></Row>
                    <Row>
                        <Col>
                            <Row><Col>
                                <MainFormElement parameterName={'Elektroautos'}/>
                            </Col>
                                <Col>
                                    <MainFormElement parameterName={'Hybridautos'}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <MainFormElement parameterName={'Fahrleistung'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col><InputHeader title={'Ausstattung'}/></Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <MainFormElement parameterName={'_equipment_kitchenRenovation'}/>
                        </Col>
                        <Col sm={6}>
                            <MainFormElement parameterName={'_equipment_washingRenovation'}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col><InputHeader title={'Steuern'}/></Col>
                    </Row>
                    <Row>
                        <Col>
                            <MainFormElement parameterName={'Grenzsteuersatz'}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'pb-5'}>
                <Col className={'d-flex justify-content-end'}>
                    <Link className={'nav-link'} to="/details">
                        <Button size={'sm'} style={{'width': '200px'}}>Weitere Details erfassen</Button>
                    </Link>
                    <Link className={'nav-link'} to="/situation">
                        <Button variant={"dark"} className={'ms-3'} style={{'width': '200px'}}
                                size={'sm'}>Situationsanalyse</Button>
                    </Link>
                </Col>
            </Row>
        </>;
    }

    let content = '';
    if (isLoading) {
        content = getLoadingMessage();
    } else {
        if (error.hasError) {
            content = getErrorMessage();
        } else {
            content = getDataForm();
        }
    }

    return <>
        <NavBreadcrumb/>
        <Container>
            {content}
        </Container>
    </>;
};