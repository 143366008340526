import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {selectElementFromTuneTargets} from "../../reducers/dataSelectors";
import {dataLoadFifthQuery, dataUpdateTuneTargetValue, dataUpdateValue} from "../../reducers/dataActions";
import {settingsSetClientEvent, settingsSetEventUID} from "../../reducers/settingsActions";

export const TuneTargetSlider = ({
                                     parameterName = 'unknown',
                                     showMinMax = false,
                                     showCurrentValue = false
                                 }) => {

    const myElement = useSelector(state => selectElementFromTuneTargets(state, parameterName));
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);

    useEffect(() => {
        if (myElement) {
            setValue(myElement.value);
        }
    }, [myElement]);

    if (!myElement) return "";

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleSliderMouseUp = () => {
        saveChanges();
    };

    const saveChanges = () => {
        dispatch(settingsSetEventUID({eventUID: parameterName}))
        dispatch(settingsSetClientEvent({clientEvent: 'editWeight'}))
        dispatch(dataUpdateTuneTargetValue({
            parameter: parameterName,
            value: value.toString()
        }));

        const defineTargetValue = value >= 51;
        console.log(parameterName, value, defineTargetValue);
        dispatch(dataUpdateValue({
            parameter: myElement.parameter, value: defineTargetValue.toString()
        }));

        dispatch(dataLoadFifthQuery());
    };

    // use rc-slider for better slider options if necessary

    const getMinValueLabel = () => {
        if (showMinMax) {
            return <label htmlFor="range" style={{marginRight: '10px'}}>{Number(myElement.minValue)}</label>;
        }
        return "";
    };

    const getMaxValueLabel = () => {
        if (showMinMax) {
            return <label htmlFor="range" style={{marginLeft: '10px'}}>{Number(myElement.maxValue)}</label>;
        }
        return "";
    };

    const getCurrentValueLable = () => {
        if (showCurrentValue) {
            return <small className={'ms-5'}>value: {value}</small>;
        }
        return "";
    };

    return (
        <>
            <div>{myElement.displayText} {getCurrentValueLable()}</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {getMinValueLabel()}
                <Form.Range
                    id="range"
                    value={value}
                    onChange={handleChange}
                    onMouseUp={handleSliderMouseUp}
                    min={myElement.minValue}
                    max={myElement.maxValue}
                    step={myElement.stepSize}
                />
                {getMaxValueLabel()}
            </div>
        </>
    );
};