import React from "react";
import Spinner from "react-bootstrap/Spinner";

export const PageTitle = ({children, showLoadingSuffix = false}) => {
    if (showLoadingSuffix) {
        return <div className={'clearfix'}>
            <h3 className={'float-start'}>
                <strong>{children}</strong>
            </h3>
            <Spinner style={{marginTop: '11px'}} className={'ms-3'} animation="border" size={"sm"}/></div>;
    } else {
        return <h3><strong>{children}</strong></h3>;
    }
};

