import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {AddressList} from "../address/AddressList";
import {useDispatch, useSelector} from "react-redux";
import {addressSearch} from "../../reducers/addressActions";
import {AddressDetail} from "../address/AddressDetail";
import {InputGroup} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {NavBreadcrumb} from "../layout/NavBreadcrumb";
import Container from "react-bootstrap/Container";
import {PageTitle} from "../layout/PageTitle";
import {logMessage} from "../../lib/logging";

export const SelectAddress = (props) => {

    const dispatch = useDispatch();
    const selectedAddress = useSelector((state) => state.address.selected);

    function handleChange(event) {
        const newValue = event.target.value;
        if (newValue === '' || newValue.length < 2) return;
        dispatch(addressSearch(newValue));
    }

    function handleSubmit(event) {
        // TODO: select first element in list of addresses
        event.preventDefault();
    }

    function getSelectAddressForm() {
        return (
            <>
                <Form onSubmit={handleSubmit}>
                    <InputGroup>
                        <FloatingLabel controlId="floatingAddress" label="Bitte gib deine Adresse ein" className="mb-3">
                            <Form.Control type="text" onChange={handleChange} placeholder={'Adresse'}/>
                        </FloatingLabel>
                    </InputGroup>
                </Form>

                <AddressList/>
            </>
        );
    }

    function handleDebugClick() {
        logMessage('debug clicked');
        dispatch(addressSearch('Bahnhofstrasse 4 Unter'));
    }

    return (
        <>
            <NavBreadcrumb/>
            <Container>
                <div>
                    {/*<NavBreadcrumb />*/}
                    <PageTitle>Gebäudeauswahl</PageTitle>
                    <strong>Ein Plus fürs Klima und deinen Komfort:</strong>
                    <p>Erfahre in wenigen Schritten, wie du dein Zuhause kostengünstig und klimafreundlich modernisieren
                        kannst.</p>
                    {selectedAddress === undefined ? printAddressForm() : <AddressDetail/>}


                </div>
            </Container>
        </>

    );

    function printAddressForm() {
        return <>
            {getSelectAddressForm()}
            <Button size={'sm'} className={'my-3'} onClick={handleDebugClick}>Test-Adresse verwenden</Button>
        </>;
    }
};