import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const InputHeader = ({title, classes = ''}) => {
    return (
        <Row className={`my-3 ${classes}`}>
            <Col>
                <strong>{title}</strong>
            </Col>
        </Row>
    );
};