import reducer from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import {configureStore} from '@reduxjs/toolkit';
import {persistStore} from "redux-persist";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore(
    {
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }).concat(middleware)
    });

sagaMiddleware.run(rootSaga);

export default store;

export const persistor = persistStore(store);