import {useSelector} from "react-redux";
import {selectDataSelectedAddress} from "../../../reducers/dataSelectors";
import React from "react";
import {transformGPS} from "../../../lib/coord-transform";

export const GeoAdminMapView = ({show=true}) => {

    const selectedAddress = useSelector(selectDataSelectedAddress);

    if (show && selectedAddress && selectedAddress.attrs) {
        // Parameter Documentation:
        // https://help.geo.admin.ch/?ids=54&lang=en

        const lat = selectedAddress.attrs.lat;
        const lon = selectedAddress.attrs.lon;
        const [lv95E, lv95N] = transformGPS(lon, lat);

        const map_url = `https://map.geo.admin.ch/embed.html?lang=de&topic=ech&bgLayer=ch.swisstopo.swissimage&E=${lv95E}&N=${lv95N}&zoom=12&crosshair=cross&embedControl=false`

        return <iframe title={'address'}
                       width="100%"
                       height="300"
                       frameBorder="0" style={{border: 0}}
                       referrerPolicy="no-referrer-when-downgrade"
                       src={map_url}
                       allowFullScreen>
        </iframe>
    } else return <><img alt={''} width={'100%'} height={'300'}/> </>
}