import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {PageTitle} from "../layout/PageTitle";
import Form from 'react-bootstrap/Form';
import {
    settingsChangeDebugUrl, settingsSetMeasureExpertView,
    settingsToggleClientEventDebug,
    settingsToggleDebug
} from "../../reducers/settingsActions";
import {InputGroup} from "react-bootstrap";

import {TACHION_URL_SUFFIX, TACHION_BASE_URL} from "../../config/index";

export const Settings = () => {

    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch();


    const handleDebugToggle = (event) => {
        dispatch(settingsToggleDebug({value: event.target.checked}));
    };

    const handleClientEventDebugToggle = (event) => {
        dispatch(settingsToggleClientEventDebug({clientEventDebug: event.target.checked}));
    };

    const handleMeasureExpertModeToggle = (event) => {
        dispatch(settingsSetMeasureExpertView({enabled: event.target.checked}));
    };

    const handleDebugUrlChange = (event) => {
        dispatch(settingsChangeDebugUrl({url: event.target.value}));
    };

    const getDebugToggle = () => {
        return <Row>
            <Col xs={3}>Custom API-Provider verwenden</Col>
            <Col>
                <Form.Check
                    type="switch"
                    id="enable-localdebug"
                    label={settings.localDebug ? 'Ja' : 'Nein'}
                    checked={settings.localDebug}
                    onChange={handleDebugToggle}
                />
            </Col>
        </Row>
            ;
    };

    const getDebugUrlInput = () => {
        if (settings.localDebug === true) {
            return <Row className={'mt-3'}>
                <Col xs={3}>API Server</Col>
                <Col xxl={6} xs={9}>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" value={settings.localDebugBaseUrl}
                                      onChange={handleDebugUrlChange}/>
                        <InputGroup.Text id="basic-addon2">{TACHION_URL_SUFFIX}</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>;
        }
        return '';
    };

    const getProdUrlInput = () => {
        if (settings.localDebug === false) {
            return <Row className={'mt-3'}>
                <Col xs={3}>API Server</Col>
                <Col xxl={6} xs={9}>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" value={TACHION_BASE_URL} disabled={true}/>
                        <InputGroup.Text id="basic-addon2">{TACHION_URL_SUFFIX}</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>;
        }
        return '';
    };

    const getClientEventDebugToggle = () => {
        return <Row>
            <Col xs={3}>clientEvent Debug-Info anzeigen</Col>
            <Col>
                <Form.Check
                    type="switch"
                    id="enable-localdebug"
                    label={settings.showClientEventDebug ? 'Ja' : 'Nein'}
                    checked={settings.showClientEventDebug}
                    onChange={handleClientEventDebugToggle}
                />
            </Col>
        </Row>
    };

    const getMeasureExportModeToggle = () => {
        return <Row>
            <Col xs={3}>Massnahmen Experten-Modus</Col>
            <Col>
                <Form.Check
                    type="switch"
                    id="enable-localdebug"
                    label={settings.measureExpertView ? 'Ja' : 'Nein'}
                    checked={settings.measureExpertView}
                    onChange={handleMeasureExpertModeToggle}
                />
            </Col>
        </Row>
    };

    return <>
        <PageTitle>Einstellungen</PageTitle>
        <hr/>
        {getDebugToggle()}
        {getDebugUrlInput()}
        {getProdUrlInput()}
        <hr/>
        {getClientEventDebugToggle()}
        <hr/>
        {getMeasureExportModeToggle()}
    </>;
};