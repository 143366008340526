import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useDispatch, useSelector} from "react-redux";
import {
    dataLoadFirstQuery,
    dataLoadFourthQuery,
    dataLoadSecondQuery,
    dataLoadThirdQuery,
} from "../../reducers/dataActions";
import {
    selectDataError,
    selectDataLoaded,
    selectDataLoading,
    selectDataSelectedAddress
} from "../../reducers/dataSelectors";
import {ImSpinner} from "react-icons/im";
import {RxCheck, RxCross1} from "react-icons/rx";

import Button from "react-bootstrap/Button";
import {logMessage} from "../../lib/logging";

function getIcon(loading, loaded) {
    if (loaded) return <RxCheck className={'text-success'}/>;
    if (loading) return <ImSpinner className={'spin'}/>;
    return <RxCross1 className={'text-danger'}/>;
}

function isLoading(loading) {
    return loading.first || loading.second || loading.third || loading.fourth;
}

function printError(error) {
    if (!error.hasError) return "";
    return <Row className={'mt-5'}>
        <Col><strong>Error : {error.message}</strong> (<small>{error.code}</small>)<br/>
            {error.detail}<br/>

        </Col>
    </Row>;
}

function getActionForQuery(queryNumber) {
    if (queryNumber === 1) return dataLoadFirstQuery;
    if (queryNumber === 2) return dataLoadSecondQuery;
    if (queryNumber === 3) return dataLoadThirdQuery;
    if (queryNumber === 4) return dataLoadFourthQuery;
    logMessage('invalid query number for selecting action');
    return () => {
    };
}

function getNameforQuery(queryNumber) {
    if (queryNumber === 1) return "1st";
    if (queryNumber === 2) return "2nd";
    if (queryNumber === 3) return "3rd";
    if (queryNumber === 4) return "4th";
    logMessage('invalid query number for selecting name');
    return "??";
}

function getBoolValueForQuery(obj, queryNumber) {
    if (queryNumber === 1) return obj.first;
    if (queryNumber === 2) return obj.second;
    if (queryNumber === 3) return obj.third;
    if (queryNumber === 4) return obj.fourth;
    return false;
}

function printQueryStatus(queryNumber, loading, loaded, selectedAddress, dispatch) {
    const name = getNameforQuery(queryNumber);
    const actionEvent = getActionForQuery(queryNumber);
    const myLoading = getBoolValueForQuery(loading, queryNumber);
    const myLoaded = getBoolValueForQuery(loaded, queryNumber);

    return <Row className={'mt-1'}>
        <Col>{name}</Col>
        <Col>{getIcon(myLoading, myLoaded)}</Col>
        <Col>
            <Button disabled={!selectedAddress || isLoading(loading)}
                    size={'sm'}
                    onClick={() => dispatch(actionEvent())}>execute</Button>
        </Col>
    </Row>;
}

function printAllQueryStatus(loading, loaded, selectedAddress, dispatch) {
    return <div>
        <Row>
            <Col></Col>
            <Col>Load Status</Col>
            <Col>Action</Col>
        </Row>
        {printQueryStatus(1, loading, loaded, selectedAddress, dispatch)}
        {printQueryStatus(2, loading, loaded, selectedAddress, dispatch)}
        {printQueryStatus(3, loading, loaded, selectedAddress, dispatch)}
        {printQueryStatus(4, loading, loaded, selectedAddress, dispatch)}

    </div>;
}

export const Query = () => {
    const loading = useSelector(selectDataLoading);
    const loaded = useSelector(selectDataLoaded);
    const selectedAddress = useSelector(selectDataSelectedAddress);
    const dataError = useSelector(selectDataError);
    const dispatch = useDispatch();

    return (<>
            <h1>Queries</h1>
            {selectedAddress ? printAllQueryStatus(loading, loaded, selectedAddress, dispatch) : "no address selected"}
            {printError(dataError)}
        </>
    );
};