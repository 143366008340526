import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';

import address from './addressReducer';
import data from './dataReducer';
import settings from './settingsReducer';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
    // blacklist: ['address', 'data']
    // whitelist: ['settings']
};

export default persistReducer(persistConfig,
    combineReducers({
            address,
            data,
            settings,
        }
    )
);
