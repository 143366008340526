import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectElementFromAnywhere} from "../../reducers/dataSelectors";
import {dataEnableParameter, dataUpdateValue} from "../../reducers/dataActions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {InputGroup} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {InputContainer} from "../layout/InputContainer";
import Button from "react-bootstrap/Button";
import {IoIosClose} from "react-icons/io";
import {logError} from "../../lib/logging";
import {getItemDisplayValue, getLabel, isInvalid} from "../../lib/display-utils";
import {InvalidElement} from "./InvalidElement";

export const MainFormToggableElement = ({
                                            parameterName = 'unknown',
                                            labelOverride = ''
                                        }) => {

    const myElement = useSelector(state => selectElementFromAnywhere(state, parameterName));

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (myElement.widgetType === 'guiTypeDropdown') {
            dispatch(dataUpdateValue({
                parameter: myElement.parameter,
                value: newValue
            }));
        } else {
            if (myElement.widgetType === 'guiTypeNoUiSliderCheck') {
                const parsedNewValue = isNaN(newValue) ? newValue : Number(newValue);
                dispatch(dataUpdateValue({
                    parameter: myElement.parameter,
                    value: parsedNewValue
                }));
            } else {
                logError(`widgetType not implemented in handleChange: ${myElement.widgetType}`);
            }
        }
    };

    const handleEnable = (enabled) => {
        if (myElement.widgetType === "guiTypeNoUiSliderCheck") {
            dispatch(dataEnableParameter({
                parameter: myElement.parameter,
                enable: enabled
            }));
        } else {
            logError(`widgetType not implemented in handleEnable: ${myElement.widgetType}`);
        }
    };

    const getFormControl = (item) => {
        let value = getItemDisplayValue(item);
        const label = getLabel(item);

        if (item.widgetType === 'guiTypeDropdown') {
            return <Form.Select size={'sm'} aria-label={label} onChange={handleChange} value={item.value}>
                {item.options.map(option => {
                    return <option key={option.uid} value={option.uid}>{option.displayText}</option>;
                })}
            </Form.Select>;
        }
        if (item.unit !== '') {
            return <InputGroup size={'sm'}>
                <Form.Control isInvalid={isInvalid(item)} type="text" value={value}
                              onChange={handleChange}
                />
                <InputGroup.Text>{item.unit}</InputGroup.Text>
            </InputGroup>;
        } else {
            return <Form.Control size={'sm'} isInvalid={isInvalid(item)}
                                 type="text" onChange={handleChange}
                                 value={value}/>;
        }
    };

    const renderField = (item) => {
        let label = getLabel(item);
        if (labelOverride !== '') label = labelOverride;
        if (item.defineValue === "true") {
            return <InputContainer>
                <Row>
                    <Form.Group as={Row} className="mb-2" controlId={`form1.${item.parameter}`}>
                        <Form.Label column={'sm'}>{label}</Form.Label>
                        <Col sm={{span: 3}}>
                            {getFormControl(item)}
                        </Col>
                        <Col sm={{span: 1}} className={'text-danger'}>
                            <IoIosClose onClick={() => handleEnable("false")}
                                        style={{cursor: 'pointer'}} size={25}/>
                        </Col>
                    </Form.Group>
                </Row>
            </InputContainer>;
        } else {
            return <InputContainer>
                <Row>
                    <Form.Group as={Row} className="mb-2" controlId={`form1.${item.parameter}`}>
                        <Form.Label column={'sm'}>{label}</Form.Label>
                        <Col sm={{span: 9}} md={{span: 2}}>
                            <Button variant={'success'} size={'sm'}
                                    onClick={() => handleEnable("true")}>hinzufügen</Button>
                        </Col>
                    </Form.Group>
                </Row>
            </InputContainer>;
        }
    };

    if (myElement === undefined) return <InvalidElement name={parameterName}/>;

    return (
        <div id={myElement.parameter}>
            {renderField(myElement)}
        </div>
    );
};
