import React from 'react';
import Container from "react-bootstrap/Container";
import {DefineTargetsCheckboxElement} from "./DefineTargetsCheckboxElement";
import {InputContainer} from "../layout/InputContainer";

import {PiSlidersHorizontalFill, PiSlidersHorizontalLight} from "react-icons/pi";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import {TuneTargetSlider} from "./TuneTargetSlider";
import {settingsUseTuneTargets} from "../../reducers/settingsActions";

export const DefineTuneTargets = () => {

    const useTuneTargets = useSelector(state => state.settings.useTuneTargets);
    const dispatch = useDispatch();

    const getTuneTargets = () => {
        if (!useTuneTargets) return "";
        return <>
            <TuneTargetSlider parameterName={'protectClimate'}/>
            <TuneTargetSlider parameterName={'enhanceComfort'}/>
            <TuneTargetSlider parameterName={'planRenewal'}/>
        </>;
    };

    const getDefineTargets = () => {
        if (useTuneTargets) return "";
        return <>
            <DefineTargetsCheckboxElement parameterName={'protectClimate'} updateTuneTarget={true}/>
            <DefineTargetsCheckboxElement parameterName={'enhanceComfort'} updateTuneTarget={true}/>
            <DefineTargetsCheckboxElement parameterName={'planRenewal'} updateTuneTarget={true}/>
        </>;
    };

    const setTuneTargetsEnabled = (newValue) => {
        dispatch(settingsUseTuneTargets({
            tuneTargets: newValue
        }));
    };

    const getTuneTargetsToggleButton = () => {
        if (useTuneTargets) {
            return <PiSlidersHorizontalFill className={'cursor-pointer'} onClick={() => setTuneTargetsEnabled(false)}
                                            size={25}/>;
        } else {
            return <PiSlidersHorizontalLight className={'cursor-pointer'} onClick={() => setTuneTargetsEnabled(true)}
                                             size={25}/>;
        }
    };
    return <InputContainer classes={'h-75 bg-opacity-10'} color={'bg-secondary'}>
        <Row className={`mt-3 mb-2`}>
            <Col>
                <strong>Was möchten Sie bei Ihrem Haus erreichen?</strong>
            </Col>
            <Col sm={"auto"}>
                {getTuneTargetsToggleButton()}
            </Col>
        </Row>
        <Container>
            {getDefineTargets()}
            {getTuneTargets()}
        </Container>
    </InputContainer>;
};
