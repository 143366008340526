import axios from "axios";

export const fetchUrl = url => {
    return axios({
        method: 'get',
        url: url,
    })
}

export const postUrl = (url, data) => {
    return axios({ method: 'post', url: url, data: data })
}