import React from 'react';
import {AddressDebug} from "./AddressDebug";
import {HouseDataDebug} from "./HouseDataDebug";
import Accordion from "react-bootstrap/Accordion";
import {LoadingDebug} from "./LoadingDebug";

export const Debug = () => {
    return (
        <div>
            <h1>Debug</h1>
            <LoadingDebug/>
            <Accordion defaultActiveKey={'housedata'}>
                <Accordion.Item key={'housedata'} eventKey={'housedata'}>
                    <Accordion.Header>House Data</Accordion.Header>
                    <Accordion.Body>
                        <HouseDataDebug/>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item key={'address'} eventKey={'address'}>
                    <Accordion.Header>Address</Accordion.Header>
                    <Accordion.Body>
                        <AddressDebug/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};