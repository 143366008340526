import React from 'react';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Header from "../layout/Header";
import {PageTitle} from "../layout/PageTitle";


export class NotFound extends React.Component {
    render() {
        return (
            <Container className={''}>
                <Header/>
                <Container>
                    <PageTitle>Seite nicht gefunden</PageTitle>
                    <p>Die angeforderte Seite konnte nicht gefunden werden.</p>
                    <Link to={'/'}>Zurück zur Startseite</Link>
                </Container>
            </Container>
        );
    }
}
