import {selectConditionalElementFromAnywhere} from "../../reducers/dataSelectors";


import React from 'react';
import {useSelector} from "react-redux";

export const ConditionalElement = ({parameter}) => {

    const first = useSelector((state) => selectConditionalElementFromAnywhere(state, parameter));

    if (!first) {
        return <p>{parameter} failed conditions</p>;
    }

    return <p>{parameter} is valid!</p>;
};