import React, {useState} from 'react';
import {PageTitle} from "../layout/PageTitle";
import {useSelector} from "react-redux";
import {selectDataFirstQuery, selectDataSecondQuery} from "../../reducers/dataSelectors";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {Form} from "react-bootstrap";
import {ConditionalElement} from "./ConditionalElement";

export const ConditionView = () => {

    const first = useSelector(selectDataFirstQuery);
    const second = useSelector(selectDataSecondQuery);

    const useFilterList = false;

    const [showNoConditionElements, setShowNoConditionElements] = useState(true);
    const [showConditionElements, setShowConditionElements] = useState(true);


    const filterList = [
        "_energySystem_heating0Renovation",
        "_building_daysAtHome",
        "_building_insulationStandard"
    ];

    const handleList = (list) => {
        if (!list) return '';
        return list.map(itm => {
            return handleItem(itm);
        });
    };

    const handleShowNoCondElementsChanged = (e) => {
        setShowNoConditionElements(e.target.checked);
    };
    const handleShowCondElementsChanged = (e) => {
        setShowConditionElements(e.target.checked);
    };

    const handleCondition = (cond) => {
        let type = 'unknown';
        if (cond.includes('?')) {
            type = 'defineValueSet';
        } else if (cond.includes('!')) {
            type = 'valueNotEqual';
        } else if (cond.includes('=')) {
            type = 'valueEquals';
        } else {
            type = <span className={'text-danger'}>UNKNOWN OPERATOR</span>;
        }
        return <li>{cond} type={type}</li>;
    };

    const handleItem = (itm) => {
        if (!useFilterList || _.includes(filterList, itm.parameter)) {
            if ((itm.conditions.length === 1) && itm.conditions[0] === null) {
                if (showNoConditionElements) return <p className={'text-primary'}>
                    <strong>{itm.parameter}</strong>
                    <span className={'small ms-1'}>(No Conditions)</span>
                </p>;
                return "";
            }

            if (!showConditionElements) return "";

            return <>
                <p>
                    <strong>{itm.parameter}</strong>
                    <span className={'ms-1 small'}>(Relation: {itm.conditionRelation})</span>
                </p>
                <ul>
                    {itm.conditions.map(c => {
                        return handleCondition(c);
                    })}
                </ul>
            </>;
        }

    };

    const getMenu = () => {
        return <>
            <Container>
                <Row><Col><strong>Settings:</strong></Col></Row>
                <Row><Col>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="sw0"
                        label="Show Elements with no condition"
                        checked={showNoConditionElements}
                        onChange={handleShowNoCondElementsChanged}
                    />
                </Col></Row>
                <Row><Col>
                    <Form.Check // prettier-ignore
                        type="switch"
                        id="sw1"
                        label="Show Elements with condition"
                        checked={showConditionElements}
                        onChange={handleShowCondElementsChanged}
                    />
                </Col></Row>
            </Container>
        </>;
    };

    return (
        <>
            <PageTitle>Condition View</PageTitle>
            {getMenu()}
            <hr/>
            <ConditionalElement parameter={'_building_atticHeatedFraction'}/>
            <ConditionalElement parameter={'_energySystem_airCondition'}/>
            <hr/>
            <h4><strong>First Query:</strong></h4>
            {handleList(first)}
            <hr/>
            <h4><strong>Second Query:</strong></h4>
            {handleList(second)}

        </>
    );
};