import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from "react-bootstrap/Container";
import Navbar from 'react-bootstrap/Navbar';
import {Link} from "react-router-dom";
import {LinkContainer} from "react-router-bootstrap";

import logo from "../../assets/img/home/logo_myEnergyGuide.png";
import {NavDropdown} from "react-bootstrap";
import {useSelector} from "react-redux";

export default function Header() {

    const settings = useSelector(state => state.settings);

    let debugBackground = '';
    if(settings && settings.localDebug) {
        debugBackground = 'bg-warning bg-opacity-50'
    }

    return (
        <Navbar bg="light" expand="lg" className={'justify-content-center mb-3'}>
            <Container className={debugBackground}>
                <Navbar.Brand>
                    <Link className={'navbar-brand'} to={'/'}>
                        <img
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="myEnergyGuide"
                        />
                        {' '}
                        myEnergyGuide

                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={'/auswahl'}>
                            <Nav.Link active={false}>Gebäudeauswahl</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={'/dateneingabe'}>
                            <Nav.Link>Dateneingabe</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to={'/disclaimer'}>
                            <Nav.Link>Disclaimer</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={'/measuredrafts'}>
                            <Nav.Link></Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
