import {useDispatch, useSelector} from "react-redux";

import React from 'react';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {addressSelect} from "../../reducers/addressActions";

export const AddressDetail = () => {
    const selectedAddress = useSelector((state) => state.address.selected);
    const dispatch = useDispatch();

    if (selectedAddress === undefined) return <></>;

    return <div>
        <Card border="dark">
            <Card.Header>
                Ausgewählte Adresse
            </Card.Header>
            <Card.Body>
                <Card.Title>
                    <strong>{selectedAddress.attrs.label.replace('<b>', '').replace('</b>', '')}</strong>
                </Card.Title>
                <Card.Text>
                    <small>ID: {selectedAddress.id}<br/>
                        Feature Id: {selectedAddress.attrs.featureId}<br/>
                        Detail: {selectedAddress.attrs.detail}<br/>
                        Latitude: {selectedAddress.attrs.lat}<br/>
                        Longitude: {selectedAddress.attrs.lon}</small><br/>
                    <Button variant="danger" size={'sm'} className={'mt-3'}
                            onClick={() => dispatch(addressSelect(undefined))}>
                        Auswahl löschen
                    </Button><br/>
                </Card.Text>
            </Card.Body>
        </Card>
    </div>;
};