import {useSelector} from "react-redux";
import {selectDataAttrs} from "../../reducers/dataSelectors";
import React from "react";
import {DetailFormRow} from "./DetailFormRow";


export const DetailFormInfoElement = ({infoType}) => {
    const attrs = useSelector(selectDataAttrs);

    let label = 'Unbekanntes Element';
    let value = <span className={'text-danger'}>Unknown Element</span>;
    let classes = '';
    let showEdit = false;
    switch (infoType) {
        case 'address':
            value = attrs.strname1;
            label = 'Adresse';
            break;
        case 'zipcity':
            value = `${attrs.plz4} ${attrs.gdename}`;
            label = 'PLZ Ort';
            break;
        default:
            break;
    }

    return <DetailFormRow label={label} showEdit={showEdit} additionalClasses={classes}>{value}</DetailFormRow>;
};